import React, { useEffect } from "react";
import classes from "./Banners.module.scss";
import { DOMAIN } from "../../utils/constants/domain.constans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { deleteBanners, getBanners, changePaginationParamsBanners } from "../../store/banners/action";
import Table from "../../components/layout/table/Table";
import Pagination from "../../components/layout/pagination/Pagination";
import { BANNERS_CREATE_ROUTE, BANNERS_ROUTE } from "../../utils/constants/routes.constants";
import CircleButton from "../../components/button/circle-button/CircleButton";
import { useNavigate } from "react-router-dom";
import { showModal } from "../../store/modal/action";

const Banners = (props) => {
  const {
    banners,
    getBanners,
    deleteBanners,
    changePaginationParamsBanners,
    paginationParams,
    totalPages,
    count,
    showModal
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    getBanners(paginationParams);
  }, [paginationParams]);

  if (!banners) {
    return (
      <div className={classes.empty}>
        Нет баннеров
        <CircleButton path={BANNERS_CREATE_ROUTE} className={classes["banners__circle-button"]}>
          <FontAwesomeIcon icon={faPlus} size="2x" />
        </CircleButton>
      </div>
    );
  }

  const onDelete = async (id) => {
    await deleteBanners(id);
    await getBanners(paginationParams);
  };

  const editBanner = (id) => {
    navigate(`${BANNERS_ROUTE}/${id}`);
  };

  const showImg = (e) => {
    showModal({
      title: "",
      children: `<img class=${classes.modal_img} src=${e.target.src} alt=${e.target.alt}/>`
    });
  };

  return (
    <div className={classes.banners}>
      <div className={classes.banners__table_wrap}>
        <Table>
          <thead>
            <tr>
              <td>Предпросмотр</td>
              <td>Название баннера</td>
              <td>Ссылка</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {banners !== null
              ? banners.map((el) => (
                  <tr key={el.id}>
                    <td>
                      {el.image ? (
                        <img src={`${DOMAIN}/public/banners/${el.image}`} onClick={(e) => showImg(e)} alt={el.image} />
                      ) : null}
                    </td>
                    <td>{el.name}</td>
                    <td>{el.url}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faEdit}
                        color={"#a8b2b5"}
                        onClick={() => editBanner(el.id)}
                        className={`${classes["banners__control"]} ${classes["banners__control--edit"]}`}
                        size="1x"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        color={"#a8b2b5"}
                        onClick={() => onDelete(el.id)}
                        className={`${classes["banners__control"]} ${classes["banners__control--remove"]}`}
                        size="1x"
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
      {banners !== null ? (
        <Pagination
          countPage={totalPages}
          countElements={count}
          action={changePaginationParamsBanners}
          defaultParams={paginationParams}
        />
      ) : null}
      <CircleButton path={BANNERS_CREATE_ROUTE} className={classes["banners__circle-button"]}>
        <FontAwesomeIcon icon={faPlus} size="2x" />
      </CircleButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banners: state.banners.data,
  totalPages: state.banners.totalPages,
  paginationParams: state.banners.paginationParams,
  count: state.banners.count
});

const mapDispatchToProps = {
  getBanners,
  showModal,
  deleteBanners,
  changePaginationParamsBanners
};

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
