export const SCENES_ROUTE = "/scenes";
export const SCENES_EDIT_ROUTE = "/scenes/:id";
export const SCENES_CREATE_ROUTE = "/scenes/create";
export const USERS_ROUTE = "/users";
export const USERS_EDIT_ROUTE = "/users/:id";
export const LOGIN_ROUTE = "/";
export const STATISTIC_ROUTE = "/statistic";
export const STATISTIC_VIEW_ROUTE = "/statistic/user-game/:id";
export const STATISTIC_SCENE_TEAMS_ROUTE = "/statistic/scene-teams/:id";
export const BANNERS_ROUTE = "/banners";
export const BANNERS_CREATE_ROUTE = "/banners/create";
export const BANNERS_EDIT_ROUTE = "/banners/:id";
export const ESSENCES_ROUTE = "/essences";
export const ESSENCES_CREATE_ROUTE = "/essences/create";
export const ESSENCES_EDIT_ROUTE = "/essences/:id";
