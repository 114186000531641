import { Api } from "../../services/api";
import { Resource } from "../../services/ApiResources";
import { ERROR, SUCCESS } from "../status/actionType";
import {
  CLEAR_ESSENCES_CURRENT,
  COUNT_ESSENCES,
  EDIT_ESSENCES,
  GET_ESSENCES,
  GET_ESSENCES_CURRENT,
  PAGINATION_PARAMS_ESSENCES,
  TOTAL_PAGES_ESSENCES,
  REMOVE_ESSENCE_TAG,
  CREATE_ESSENCE_TAG,
  ESSENCE_TAG_HINTS,
  ESSENCE_CLONE
} from "./actionType";
import { confirmAlert } from "react-confirm-alert";

export const changePaginationParamsEssences = (paginationParams) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_PARAMS_ESSENCES,
      payload: paginationParams
    });
  };
};

export const createEssence = (data) => {
  return async (dispatch) => {
    try {
      const response = await Api.POST(Resource.ESSENCES, data);
      dispatch({
        type: SUCCESS,
        payload: response.success
      });
      return response.essence;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const getEssences = (params) => {
  return async (dispatch) => {
    try {
      const response = await Api.GET(
        {
          url: Resource.ESSENCES
        },
        params
      );
      if (response) {
        dispatch({
          type: TOTAL_PAGES_ESSENCES,
          payload: response.totalPages
        });
        dispatch({
          type: COUNT_ESSENCES,
          payload: response.essences.count
        });
        dispatch({
          type: GET_ESSENCES,
          payload: response.essences.rows
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getCurrentEssence = (id) => {
  return async (dispatch) => {
    try {
      const response = await Api.GET({
        id,
        url: Resource.ESSENCES
      });

      dispatch({
        type: GET_ESSENCES_CURRENT,
        payload: response
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const editEssences = (formData) => {
  return async (dispatch, getState) => {
    try {
      const { data } = getState().essences;
      const response = await Api.PUT(Resource.ESSENCES, formData);
      if (response && (!data || !data.length)) {
        dispatch({
          type: EDIT_ESSENCES,
          payload: [response.essence]
        });
        dispatch({
          type: SUCCESS,
          payload: response.success
        });
      }
      if (response && data && data.length) {
        const upload_data = data.map((el) => (el.id === response.essence.id ? response.essence : el));
        dispatch({
          type: EDIT_ESSENCES,
          payload: upload_data
        });
        dispatch({
          type: SUCCESS,
          payload: response.success
        });
      }

      return response.essence;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const clearEssence = (id) => {
  return async (dispatch) => {
    try {
      const response = await Api.PUT(Resource.ESSENCES_CLEAR, { id });
      dispatch({
        type: CLEAR_ESSENCES_CURRENT,
        payload: response.essence
      });
      dispatch({
        type: SUCCESS,
        payload: response.success
      });
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const deleteEssence = (params) => {
  return async (dispatch) => {
    try {
      confirmAlert({
        title: "Подтверждение",
        message: "Удалить объект?",
        buttons: [
          {
            label: "Да",
            onClick: async () => {
              const res = await Api.DELETE(Resource.ESSENCES, params.id);
              if (res) {
                dispatch({
                  type: SUCCESS,
                  payload: res.success
                });
              }

              const response = await Api.GET(
                {
                  url: Resource.ESSENCES
                },
                params
              );

              if (response) {
                dispatch({
                  type: TOTAL_PAGES_ESSENCES,
                  payload: response.totalPages
                });
                dispatch({
                  type: COUNT_ESSENCES,
                  payload: response.essences.count
                });
                dispatch({
                  type: GET_ESSENCES,
                  payload: response.essences.rows
                });
              }
            }
          },
          {
            label: "Нет"
          }
        ]
      });
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const createEssenceTag = (data) => {
  return async (dispatch) => {
    try {
      const res = await Api.POST(Resource.TAGS, { ...data, type: "ESSENCE" });
      if (res) {
        dispatch({
          type: CREATE_ESSENCE_TAG,
          payload: res
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const removeEssenceTag = (id, objectId) => {
  return async (dispatch) => {
    try {
      const res = await Api.DELETE(Resource.TAGS, id, { type: "ESSENCE", objectId });
      if (res) {
        dispatch({
          type: REMOVE_ESSENCE_TAG,
          payload: id
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const essenceTagHintsGet = (query) => {
  return async (dispatch) => {
    try {
      const res = await Api.GET({ url: Resource.TAG_HINTS }, { type: "ESSENCE", query });
      if (res) {
        dispatch({
          type: ESSENCE_TAG_HINTS,
          payload: res
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const cloneEssence = (data) => {
  return async (dispatch) => {
    try {
      confirmAlert({
        title: "Подтверждение",
        message: "Клонировать объект?",
        buttons: [
          {
            label: "Да",
            onClick: async () => {
              const res = await Api.POST(`${Resource.ESSENCES}/${data.id}/clone`, data);

              if (res) {
                dispatch({
                  type: SUCCESS,
                  payload: res.success
                });
                dispatch({
                  type: ESSENCE_CLONE,
                  payload: res.essence
                });
              }
            }
          },
          {
            label: "Нет"
          }
        ]
      });
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};
