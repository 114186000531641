import React from "react";
import {
  SCENES_ROUTE,
  LOGIN_ROUTE,
  USERS_ROUTE,
  SCENES_CREATE_ROUTE,
  USERS_EDIT_ROUTE,
  SCENES_EDIT_ROUTE,
  STATISTIC_ROUTE,
  STATISTIC_VIEW_ROUTE,
  BANNERS_ROUTE,
  BANNERS_CREATE_ROUTE,
  BANNERS_EDIT_ROUTE,
  ESSENCES_ROUTE,
  ESSENCES_CREATE_ROUTE,
  ESSENCES_EDIT_ROUTE,
  STATISTIC_SCENE_TEAMS_ROUTE
} from "./utils/constants/routes.constants";
import Auth from "./views/auth/Auth";
import Scenes from "./views/scenes/Scenes";
import Users from "./views/users/Users";
import ScenesCreate from "./views/scenes/create/ScenesCreate";
import UserEdit from "./views/users/edit/UserEdit";
import ScenesEdit from "./views/scenes/edit/ScenesEdit";
import Statistic from "./views/statistic/Statistic";
import UserGameStatistics from "./views/statistic/user-game-statistics/userGameStatistics";
import Banners from "./views/banners/Banners";
import BannersCreate from "./views/banners/create/BannersCreate";
import BannersEdit from "./views/banners/edit/BannersEdit";
import Essences from "./views/essences/Essences";
import EssencesCreate from "./views/essences/create/EssencesCreate";
import EssencesEdit from "./views/essences/edit/EssencesEdit";
import TeamsStatistic from "./views/statistic/teams-statistic/TeamsStatistic";

export const authRoutes = [
  {
    path: SCENES_ROUTE,
    Component: <Scenes />
  },
  {
    path: SCENES_CREATE_ROUTE,
    Component: <ScenesCreate />
  },
  {
    path: SCENES_EDIT_ROUTE,
    Component: <ScenesEdit />
  },
  {
    path: USERS_ROUTE,
    Component: <Users />
  },
  {
    path: USERS_EDIT_ROUTE,
    Component: <UserEdit />
  },
  {
    path: STATISTIC_ROUTE,
    Component: <Statistic />
  },
  {
    path: STATISTIC_VIEW_ROUTE,
    Component: <UserGameStatistics />
  },
  {
    path: STATISTIC_SCENE_TEAMS_ROUTE,
    Component: <TeamsStatistic />
  },
  {
    path: BANNERS_ROUTE,
    Component: <Banners />
  },
  {
    path: BANNERS_CREATE_ROUTE,
    Component: <BannersCreate />
  },
  {
    path: BANNERS_EDIT_ROUTE,
    Component: <BannersEdit />
  },
  {
    path: ESSENCES_ROUTE,
    Component: <Essences />
  },
  {
    path: ESSENCES_CREATE_ROUTE,
    Component: <EssencesCreate />
  },
  {
    path: ESSENCES_EDIT_ROUTE,
    Component: <EssencesEdit />
  }
];

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: <Auth />
  }
];
