import { Api } from "../../services/api";
import { Resource } from "../../services/ApiResources";
import {
  CLEAR_LIST_VIRTUAL_OBJECT,
  CREATE_BEACON_CHILDREN,
  CREATE_VIRTUAL_OBJECT,
  DELETE_BEACON_CHILDREN,
  DELETE_VIRTUAL_OBJECT,
  DRAG_AND_DROP_BEACON_CHILDREN,
  EDIT_BEACON_CHILDREN,
  EDIT_VIRTUAL_OBJECT,
  GET_VIRTUAL_OBJECT,
  TOOGLE_CONSISTENT
} from "./actionType";
import { ERROR, SUCCESS } from "../status/actionType";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export const createVirtualObject = (data) => {
  return async (dispatch, getState) => {
    try {
      const { virtual_objects } = getState().virtualObject;
      const res = await Api.POST(Resource.VIRTUAL_OBJECT, data);

      if (res) {
        let newList;

        if (virtual_objects) newList = [res.virtual_object, ...virtual_objects];
        if (!virtual_objects) newList = [res.virtual_object];

        dispatch({
          type: SUCCESS,
          payload: res.success
        });
        dispatch({
          type: CREATE_VIRTUAL_OBJECT,
          payload: newList
        });
        return res.virtual_object;
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const getVirtualObject = (id, params) => {
  return async (dispatch) => {
    try {
      const response = await Api.GET(
        {
          url: Resource.VIRTUAL_OBJECT,
          id
        },
        params
      );

      if (response) {
        dispatch({
          type: GET_VIRTUAL_OBJECT,
          payload: response.virtual_objects
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const clearListVirtualObject = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LIST_VIRTUAL_OBJECT,
      payload: null
    });
  };
};

export const deleteVirtualObject = (id) => {
  return async (dispatch, getState) => {
    try {
      const res = await Api.DELETE(Resource.VIRTUAL_OBJECT, id);
      if (res) {
        const { virtual_objects } = getState().virtualObject;

        const newVirtual = virtual_objects
          .map((el) => {
            if (el.id !== +id) {
              return el;
            }
          })
          .filter((element) => element !== undefined);

        dispatch({
          type: SUCCESS,
          payload: res.success
        });
        dispatch({
          type: DELETE_VIRTUAL_OBJECT,
          payload: newVirtual
        });
      }
      return res;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const clearVirtualObject = (id) => {
  return async (dispatch) => {
    try {
      confirmAlert({
        title: "Подтверждение",
        message: "Очистить сцену?",
        buttons: [
          {
            label: "Да",
            onClick: async () => {
              const res = await Api.DELETE(Resource.VIRTUAL_OBJECT_CLEAR, id);
              if (res) {
                dispatch({
                  type: SUCCESS,
                  payload: res.success
                });
                dispatch({
                  type: CLEAR_LIST_VIRTUAL_OBJECT,
                  payload: null
                });
                return res;
              }
            }
          },
          {
            label: "Нет"
          }
        ]
      });
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const editVirtualObject = (data, id) => {
  return async (dispatch, getState) => {
    try {
      const res = await Api.PUT(`${Resource.VIRTUAL_OBJECT_EDIT}/${id}`, data);
      if (res) {
        const { virtual_objects } = getState().virtualObject;

        const newEditVirtual = virtual_objects.map((el) => {
          if (el.id === res.virtual_object.id) {
            return res.virtual_object;
          }

          return el;
        });

        dispatch({
          type: SUCCESS,
          payload: res.success
        });
        dispatch({
          type: EDIT_VIRTUAL_OBJECT,
          payload: newEditVirtual
        });
      }
      return res;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const createBeaconChildren = (data) => {
  return async (dispatch, getState) => {
    try {
      const { virtual_objects } = getState().virtualObject;
      const res = await Api.POST(Resource.BEACON_CHILDREN, data);
      if (res) {
        let virtual_object = virtual_objects.find((el) => el.id === res.beacon_children.virtualObjectId);

        if (virtual_object) {
          virtual_object.beacon_children = [...virtual_object.beacon_children, res.beacon_children];
        }

        const indexFoundVirtualObject = virtual_objects.findIndex((item) => item.id === virtual_object.id);
        virtual_objects[indexFoundVirtualObject] = { ...virtual_object };

        dispatch({
          type: SUCCESS,
          payload: res.success
        });
        dispatch({
          type: CREATE_BEACON_CHILDREN,
          payload: virtual_objects
        });

        return res.beacon_children;
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const editBeaconChildren = (data, id) => {
  return async (dispatch, getState) => {
    try {
      const res = await Api.PUT(`${Resource.BEACON_CHILDREN_EDIT}/${id}`, data);
      if (res) {
        const { virtual_objects } = getState().virtualObject;

        let virtual_object = virtual_objects.find((el) => el.id === res.beacon_children.virtualObjectId);

        if (virtual_object) {
          let beacon_children = virtual_object.beacon_children.find((el) => el.id === id);
          const indexFoundBeaconChildren = virtual_object.beacon_children.findIndex(
            (item) => item.id === beacon_children.id
          );
          virtual_object.beacon_children[indexFoundBeaconChildren] = { ...res.beacon_children };
        }

        const indexFoundVirtualObject = virtual_objects.findIndex((item) => item.id === virtual_object.id);
        virtual_objects[indexFoundVirtualObject] = { ...virtual_object };

        dispatch({
          type: SUCCESS,
          payload: res.success
        });
        dispatch({
          type: EDIT_BEACON_CHILDREN,
          payload: virtual_objects
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const dragAndDropBeaconChildren = (data, id) => {
  return async (dispatch, getState) => {
    try {
      const res = await Api.PUT(`/virtual-object/edit/${id}/beacon-children/drag-and-drop`, data);
      if (res) {
        const { virtual_objects } = getState().virtualObject;

        let virtual_object = virtual_objects.find((el) => el.id === +id);
        virtual_object = { ...virtual_object, beacon_children: res.beacon_children };

        const indexFoundVirtualObject = virtual_objects.findIndex((item) => item.id === virtual_object.id);
        virtual_objects[indexFoundVirtualObject] = { ...virtual_object };

        dispatch({
          type: DRAG_AND_DROP_BEACON_CHILDREN,
          payload: virtual_objects
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const toggleConsistent = (data) => {
  return async (dispatch, getState) => {
    try {
      const { virtual_objects } = getState().virtualObject;
      const res = await Api.PUT(`${Resource.VIRTUAL_OBJECT_TOGGLE_CONSISTENT}/${data.id}`, data);
      if (res) {
        let virtual_object = virtual_objects.find((el) => el.id === res.id);

        if (virtual_object) {
          virtual_object.isConsistent = res.isConsistent;
        }

        const indexFoundVirtualObject = virtual_objects.findIndex((item) => item.id === virtual_object.id);
        virtual_objects[indexFoundVirtualObject] = { ...virtual_object };

        dispatch({
          type: TOOGLE_CONSISTENT,
          payload: virtual_objects
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const deleteBeaconChildren = (virtualObjectId, id) => {
  return async (dispatch, getState) => {
    try {
      const res = await Api.DELETE(Resource.BEACON_CHILDREN, id);
      if (res) {
        const { virtual_objects } = getState().virtualObject;

        let virtual_object = virtual_objects.find((el) => el.id === +virtualObjectId);

        let beacon_children = virtual_object.beacon_children.filter((el) => el.id !== +id);
        virtual_object = { ...virtual_object, beacon_children: [...beacon_children] };

        const indexFoundVirtualObject = virtual_objects.findIndex((item) => item.id === virtual_object.id);
        virtual_objects[indexFoundVirtualObject] = { ...virtual_object };

        dispatch({
          type: SUCCESS,
          payload: res.success
        });
        dispatch({
          type: DELETE_BEACON_CHILDREN,
          payload: virtual_objects
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};
