import React from "react";
import styles from "./styles.module.scss";

const Info = (props) => {
  const { children } = props;
  return (
    <div className={styles.info}>
      <div className={styles.info__icon}>
        ?<div className={styles.info__content}>{children}</div>
      </div>
    </div>
  );
};

export default Info;
