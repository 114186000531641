import React, { useState } from "react";
import classes from "./Auth.module.scss";
import { loginUser } from "../../store/users/action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SCENES_ROUTE } from "../../utils/constants/routes.constants";
import Form from "../../components/form/Form";
import Input from "../../components/form/input/Input";
import Button from "../../components/button/Button";

const Auth = (props) => {
  const { loginUser } = props;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");

  const onClick = async () => {
    await loginUser(email, password);
    navigate(SCENES_ROUTE);
  };

  return (
    <div className={classes.auth}>
      <Form className={classes.auth__form}>
        <h2 className={classes.auth__title}>Please login</h2>
        <Input
          placeholder={"Enter your login..."}
          label={"Login"}
          type={"text"}
          name={"login"}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          placeholder={"Enter your password..."}
          label={"Password"}
          type={"password"}
          name={"password"}
          onChange={(e) => setPass(e.target.value)}
        />
        <Button className={classes.auth__button} onClick={() => onClick()}>
          Log in
        </Button>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  loginUser
};

export default connect(null, mapDispatchToProps)(Auth);
