import { CREATE_POLYGON, CREATE_COORDINATES, GET_POLYGONS, DELETE_POLYGON } from "./actionType";

const initialState = {
  polygons: null
};

const mapReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_POLYGONS:
      return {
        ...state,
        polygons: payload
      };
    case CREATE_POLYGON:
      return {
        ...state,
        polygons: payload
      };
    case CREATE_COORDINATES:
      return {
        ...state,
        polygons: payload
      };
    case DELETE_POLYGON:
      return {
        ...state,
        polygons: payload
      };
    default:
      return {
        ...state
      };
  }
};

export default mapReducer;
