import React, { useEffect, useState } from "react";
import classes from "./ScenesObject.module.scss";
import Form from "../../../../components/form/Form";
import Input from "../../../../components/form/input/Input";
import Checkbox from "../../../../components/form/checkbox/Checkbox";
import { restrict } from "../../../../helpers/RestrictHelper";
import Button from "../../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { SCENES_ROUTE } from "../../../../utils/constants/routes.constants";
import { ALTITUDE_MODELS } from "../../../../utils/constants/altitude-modes.constants";
import Select from "react-select";
import { connect } from "react-redux";
import { getBanners } from "../../../../store/banners/action";
import { Switch } from "pretty-checkbox-react";
import {
  changeTeamGame,
  createSceneTag,
  getSceneCurrent,
  removeSceneTag,
  sceneTagHintsGet
} from "../../../../store/scenes/action";
import TeamItem from "../teamItem/TeamItem";
import { editTeam, createTeam, getTeamsScene } from "../../../../store/teams/action";
import Tags from "../../../../components/hashtags/Tags";
import Info from "../../../../components/form/info/Info";

const customStyles = {
  container: (provided) => ({
    ...provided
  }),
  control: (provided) => ({
    ...provided,
    "borderRadius": "8px",
    "borderColor": "#000",
    "boxShadow": "none",
    "&:hover": {
      borderColor: "none"
    }
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "300px"
  })
};

const ScenesObject = (props) => {
  const {
    sceneCurrent,
    sceneTagHints,
    request,
    setSceneId,
    sceneId,
    getBanners,
    banners,
    changeTeamGame,
    getTeamsScene,
    teamsState,
    createTeam,
    editTeam,
    getSceneCurrent,
    createSceneTag,
    removeSceneTag,
    sceneTagHintsGet
  } = props;
  const navigate = useNavigate();

  const [nameGame, setNameGame] = useState("");
  const [gameTime, setGameTime] = useState(0);
  const [visibilityDistance, setVisibilityDistance] = useState(30);
  const [maxCaptureDistance, setMaxCaptureDistance] = useState(2);
  const [isFullName, setIsFullName] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [errorNameGame, setErrorNameGame] = useState("");
  const [errorCheckbox, setErrorCheckbox] = useState("");
  const [bannerSelected, setBannerSelected] = useState(null);
  const [tags, setTags] = useState([]);
  const [options, setOptions] = useState([
    {
      value: "NULL",
      label: "Баннер не выбран"
    }
  ]);
  const [isTeam, setIsTeam] = useState(false);
  const [isDelayedStart, setIsDelayedStart] = useState(false);
  const [teams, setTeams] = useState([]);
  const [isTeamCreated, setIsTeamCreated] = useState(true);
  const [delayedStartDate, setDelayedStartDate] = useState(new Date().toISOString().split("T")[0]);
  const [delayedStartTime, setDelayedStartTime] = useState("00:00");
  const [altitudeMode, setAltitudeMode] = useState("DeviceRelative");
  const [restartWhenARTrackingIsRestored, setRestartWhenARTrackingIsRestored] = useState(true);

  useEffect(() => {
    if (sceneId) {
      getSceneCurrent(sceneId);
      getTeamsScene(sceneId);
    }
  }, [sceneId]);

  useEffect(() => {
    if (teamsState) setTeams(teamsState);
  }, [teamsState]);

  useEffect(() => {
    (async () => {
      try {
        await getBanners();
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (banners) {
      const newBanners = banners.map((el) => {
        return {
          value: el.id,
          label: el.name
        };
      });

      setOptions(newBanners);
    }
  }, [banners]);

  useEffect(() => {
    if (sceneId) {
      if (Object.keys(sceneCurrent).length && sceneId) {
        setTags(sceneCurrent.tags);
        setNameGame(sceneCurrent.name_game);
        setGameTime(sceneCurrent.game_time);
        setVisibilityDistance(sceneCurrent.visibility_distance);
        setMaxCaptureDistance(sceneCurrent.max_capture_distance);
        setIsFullName(sceneCurrent.is_full_name);
        setIsEmail(sceneCurrent.is_email);
        setIsPhone(sceneCurrent.is_phone);
        setIsTeam(sceneCurrent.isTeamGame);
        setAltitudeMode(sceneCurrent.altitudeMode);
        setIsDelayedStart(sceneCurrent.isDelayedStart);
        setRestartWhenARTrackingIsRestored(sceneCurrent.restartWhenARTrackingIsRestored);
        if (sceneCurrent.delayedStartDate) {
          setDelayedStartDate(new Date(sceneCurrent.delayedStartDate).toISOString().split("T")[0]);
          setDelayedStartTime(new Date(sceneCurrent.delayedStartDate).toLocaleTimeString());
        }
        if (sceneCurrent.banner) setBannerSelected(sceneCurrent.banner.id);
      }
      navigate(`${SCENES_ROUTE}/${sceneId}`);
    }
  }, [sceneCurrent, sceneId]);

  const onClick = async () => {
    try {
      setErrorNameGame("");
      setErrorCheckbox("");
      if (!nameGame.length) return setErrorNameGame("Введите название игры");

      if (isFullName || isEmail || isPhone) {
        const data = {
          name_game: nameGame,
          game_time: gameTime.length ? gameTime : 0,
          visibility_distance: visibilityDistance,
          max_capture_distance: maxCaptureDistance,
          is_full_name: isFullName,
          is_email: isEmail,
          is_phone: isPhone,
          altitudeMode,
          bannerId: bannerSelected,
          isDelayedStart,
          restartWhenARTrackingIsRestored,
          delayedStartDate: new Date(delayedStartDate + " " + delayedStartTime)
        };

        const res = await request(data, sceneId);
        if (res && !sceneId) setSceneId(res.id);
      } else {
        return setErrorCheckbox("Не выбран чекбокс");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const onChangeTeamGame = (isChecked) => {
    setIsTeam(isChecked);
    changeTeamGame(isChecked, sceneId);
  };

  const onChangeDelayedStart = (isChecked) => {
    setIsDelayedStart(isChecked);
  };

  const addTeam = () => {
    setTeams([
      ...teams,
      {
        name: "",
        color: ""
      }
    ]);
    setIsTeamCreated(false);
  };

  const onCreateTeam = (data) => {
    createTeam({
      name_team: data.name_team,
      color: data.color,
      sceneId
    });
    setIsTeamCreated(data.isTeamCreated);
  };

  const onEditTeam = (data) => {
    editTeam({
      id: data.id,
      name_team: data.name_team,
      color: data.color
    });
    setIsTeamCreated(data.isTeamCreated);
  };

  const onTouchTeamEdit = (data) => {
    setIsTeamCreated(data.isTeamCreated);
  };

  const onChangeDate = (e) => {
    setDelayedStartDate(e.target.value);
  };

  const onChangeTime = (e) => {
    setDelayedStartTime(e.target.value);
  };

  return (
    <div className={classes["scenes-object"]}>
      {sceneId ? (
        <Tags
          tags={tags}
          tagHints={sceneTagHints}
          addTag={createSceneTag}
          objectId={sceneId}
          removeTag={removeSceneTag}
          tagHintsAction={sceneTagHintsGet}
        />
      ) : null}
      <Form>
        <Input
          type={"text"}
          name={"name-game"}
          classNameInput={classes["scenes-object__input"]}
          placeholder={"Введите название игры"}
          value={nameGame}
          error={errorNameGame}
          onChange={(e) => setNameGame(e.target.value)}
        />
        <div className={classes["scenes-object__checkbox"]}>
          <Checkbox
            type={"checkbox"}
            text={"ФИО"}
            checked={isFullName}
            onChange={(e) => setIsFullName(e.target.checked)}
          />
          <Checkbox type={"checkbox"} text={"Почта"} checked={isEmail} onChange={(e) => setIsEmail(e.target.checked)} />
          <Checkbox
            type={"checkbox"}
            text={"Телефон"}
            checked={isPhone}
            onChange={(e) => setIsPhone(e.target.checked)}
          />
        </div>
        {errorCheckbox.length ? (
          <span className={classes["scenes-object__checkbox--error"]}>{errorCheckbox}</span>
        ) : null}
        <Input
          type={"number"}
          name={"game-time"}
          label={"Время игры (сек)"}
          value={gameTime}
          placeholder={"Введите время игры"}
          onChange={(e) => restrict(e, 6, setGameTime)}
        />
        <Input
          type={"number"}
          name={"visibility-distance"}
          label={"Дистанция видимости объектов (см)"}
          value={visibilityDistance}
          placeholder={"Введите дистанцию видимости объектов"}
          onChange={(e) => restrict(e, 6, setVisibilityDistance)}
        />
        <Input
          type={"number"}
          name={"max-capture-distance"}
          label={"Максимальная дистанция для «захвата» объектов (см)"}
          value={maxCaptureDistance}
          placeholder={"Введите максимальную дистанцию для «захвата» объектов"}
          onChange={(e) => restrict(e, 6, setMaxCaptureDistance)}
        />
        <div className={classes["scenes-object__select_wrap"]}>
          <p>Баннер</p>
          <Select
            options={options}
            styles={customStyles}
            onChange={(e) => setBannerSelected(e.value)}
            placeholder={"Выберите баннер"}
            value={options.filter((option) => option.value === bannerSelected)}
          />
        </div>
        <div className={classes["scenes-object__select_wrap"]}>
          <div className={classes["scenes-object__select_wrap--top"]}>
            <p>The altitude mode</p>
            <Info>
              <p>Режим высоты:</p>
              <p><strong>«Absolute»</strong> - абсолютная высота относительно уровня моря.</p>
              <p><strong>«DeviceRelative»</strong> - высота относительно начального положения устройства.</p>
              <p><strong>«GroundRelative»</strong> - высота относительно ближайшей обнаруженной поверхности.</p>
              <p><strong>«Ignore»</strong> - высота игнорируется (эквивалентно установке ее на ноль)</p>
              <p>по умолчанию - DeviceRelative</p>
            </Info>
          </div>
          <Select
            options={ALTITUDE_MODELS}
            styles={customStyles}
            onChange={(e) => setAltitudeMode(e.value)}
            placeholder={"The altitude mode"}
            value={ALTITUDE_MODELS.filter((item) => item.value === altitudeMode)}
          />
        </div>
        <div className={`${classes["scenes-object__checkbox"]} ${classes["scenes-object__checkbox--RestartWhenARTrackingIsRestored"]}`}>
          <Checkbox
            type={"checkbox"}
            text={"Restart When AR Tracking Is Restored"}
            checked={restartWhenARTrackingIsRestored}
            onChange={(e) => setRestartWhenARTrackingIsRestored(e.target.checked)}
          />
          <Info>
            Перезапускать при восстановлении отслеживания дополненной реальности — если установлено true, каждый раз,
            когда отслеживание дополненной реальности теряется и восстанавливается, система AR+GPS будет
            перезапускаться, перемещая все объекты.
          </Info>
        </div>
        {sceneId ? (
          <div className={classes["scenes-object__teams"]}>
            <Switch
              className={classes["scenes-object__switch-teams"]}
              state={isTeam}
              setState={setIsTeam}
              onChange={(e) => onChangeTeamGame(e.target.checked)}>
              Командная игра
            </Switch>
            <Button
              disabled={!isTeam || !isTeamCreated}
              className={classes["scenes-object__teams--add"]}
              onClick={() => addTeam()}>
              +
            </Button>
            <div className={classes["scenes-object__teams--wrap"]}>
              {teams && teams.length
                ? teams.map((item, i) => (
                    <TeamItem
                      key={i}
                      team={item}
                      onCreateTeam={onCreateTeam}
                      onEditTeam={onEditTeam}
                      onTouchTeamEdit={onTouchTeamEdit}
                      isTeam={isTeam}
                    />
                  ))
                : null}
            </div>
          </div>
        ) : null}
        {sceneId ? (
          <div className={classes["scenes-object__delayed-start"]}>
            <Switch
              className={classes["scenes-object__switch-delayed-start"]}
              state={isDelayedStart}
              setState={setIsDelayedStart}
              onChange={(e) => onChangeDelayedStart(e.target.checked)}>
              Задать дату и время начала игры
            </Switch>
            {isDelayedStart ? (
              <div className={classes["scenes-object__delayed-start--wrap-input"]}>
                <input type={"date"} onChange={onChangeDate} value={delayedStartDate} />
                <input type={"time"} onChange={onChangeTime} value={delayedStartTime} />
              </div>
            ) : null}
          </div>
        ) : null}
        {!sceneId && (
          <Button disabled={sceneId} className={classes["scenes-object__button"]} onClick={() => onClick()}>
            Создать
          </Button>
        )}
        {sceneId && (
          <Button className={classes["scenes-object__button"]} onClick={() => onClick()}>
            Изменить
          </Button>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sceneCurrent: state.scenes.sceneCurrent,
  sceneTagHints: state.scenes.sceneTagHints,
  banners: state.banners.data,
  teamsState: state.teams.data
});

const mapDispatchToProps = {
  getBanners,
  changeTeamGame,
  getTeamsScene,
  createTeam,
  editTeam,
  getSceneCurrent,
  createSceneTag,
  removeSceneTag,
  sceneTagHintsGet
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenesObject);
