import React from "react";
import classes from "./Input.module.scss";

const Input = (props) => {
  const {
    label,
    type,
    name,
    placeholder,
    value,
    onChange,
    error,
    classNameWrap,
    classNameInput,
    classNameLabel,
    classNameError,
    disabled
  } = props;

  return (
    <div className={classNameWrap ? `${classes["wrap"]} ${classNameWrap}` : classes["wrap"]}>
      {label && (
        <label
          htmlFor={name}
          className={classNameLabel ? `${classes["wrap__label"]} ${classNameLabel}` : classes["wrap__label"]}>
          {label}
        </label>
      )}
      <input
        name={name}
        disabled={disabled}
        id={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        className={classNameInput ? `${classes["wrap__input"]} ${classNameInput}` : classes["wrap__input"]}
      />
      {error && (
        <span className={classNameError ? `${classes["wrap__error"]} ${classNameError}` : classes["wrap__error"]}>
          {error}
        </span>
      )}
    </div>
  );
};

export default Input;
