import React from "react";
import classes from "./Checkbox.module.scss";

const Checkbox = (props) => {
  const { checked, name, type, idElement, text, classNameCheckbox, classNameCheckmark, onChange } = props;
  return (
    <label className={classNameCheckbox ? `${classNameCheckbox} ${classes.checkbox}` : classes.checkbox}>
      {text}
      <input
        checked={checked}
        data-id-element={idElement && idElement}
        type={type}
        name={name}
        onChange={(e) => onChange(e)}
      />
      <span className={classNameCheckmark ? `${classNameCheckmark} ${classes.checkmark}` : classes.checkmark}></span>
    </label>
  );
};

export default Checkbox;
