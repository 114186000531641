import React from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import SearchField from "./components/SearchField";
import CustomMarker from "./components/CustomMarker";

const colorMarkerActive = "#cc0000";

const Map = (props) => {
  const {
    isConsistent,
    virtualObjects,
    beaconChildren,
    selectedVirtualObjId,
    selectedBeaconChildrenId,
    onCreateMarker,
    eventHandlersVirtualObject,
    eventHandlersBeaconChildren
  } = props;

  const mapData = {
    center: [55.06062282702159, 82.89465571264769],
    zoom: 4
  };

  const MyComponent = () => {
    useMapEvents({
      click: async (e) => {
        await onCreateMarker(e.latlng.lng, e.latlng.lat);
      }
    });
    return null;
  };

  const renderIconMarker = (colorIcon) => {
    return `<div style="display: flex; align-items: center; font-size: 14px">
                    <div class="iconMarker" style="border-color: ${colorIcon ? colorIcon : "#00aeff"}">   
                    </div>
                </div>`;
  };

  return (
    <div>
      <MapContainer
        style={{
          marginTop: "20px",
          height: "500px",
          width: "100wh"
        }}
        zoom={mapData.zoom}
        zoomControl={false}
        center={mapData.center}
        scrollWheelZoom={true}>
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
          maxZoom={25}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <MyComponent />
        <SearchField />
        {virtualObjects && virtualObjects.length
          ? virtualObjects.map((el) => (
              <CustomMarker
                eventHandlers={eventHandlersVirtualObject}
                element={el}
                key={el.id}
                renderIconMarker={renderIconMarker}
                selectedMarker={selectedVirtualObjId}
                color={colorMarkerActive}
              />
            ))
          : null}
        {isConsistent && beaconChildren && beaconChildren.length
          ? beaconChildren.map((el) => (
              <CustomMarker
                eventHandlers={eventHandlersBeaconChildren}
                element={el}
                key={el.id}
                renderIconMarker={renderIconMarker}
                selectedMarker={selectedBeaconChildrenId}
                color={colorMarkerActive}
              />
            ))
          : null}
      </MapContainer>
    </div>
  );
};

export default Map;
