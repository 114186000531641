import {
  GET_SCENES,
  COUNT_SCENES,
  TOTAL_PAGES_SCENES,
  PAGINATION_PARAMS_SCENES,
  GET_SCENE_CURRENT,
  CREATE_SCENE_TAG,
  REMOVE_SCENE_TAG,
  SCENE_TAG_HINTS,
  SCENE_CLONE
} from "./actionType";

const initialState = {
  scenes: [],
  sceneCurrent: {},
  sceneTagHints: null,
  paginationParams: {
    page: 0,
    pageSize: 10
  },
  totalPages: null,
  count: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SCENE_CLONE: {
      const addedScene = [payload, ...state.scenes];
      if (addedScene.length > state.paginationParams.pageSize) {
        addedScene.pop();
      }

      const newTotalPages = Math.ceil((state.count + 1) / state.paginationParams.pageSize);

      return {
        ...state,
        scenes: [...addedScene],
        count: state.count + 1,
        totalPages: newTotalPages
      };
    }
    case GET_SCENES:
      return {
        ...state,
        scenes: payload
      };
    case GET_SCENE_CURRENT:
      return {
        ...state,
        sceneCurrent: payload
      };
    case CREATE_SCENE_TAG:
      state.sceneCurrent.tags = [...state.sceneCurrent.tags, payload];
      return {
        ...state,
        sceneCurrent: { ...state.sceneCurrent }
      };
    case REMOVE_SCENE_TAG: {
      const sceneFiltered = state.sceneCurrent.tags.filter((item) => item.id !== payload);
      state.sceneCurrent.tags = [...sceneFiltered];

      return {
        ...state,
        sceneCurrent: { ...state.sceneCurrent }
      };
    }
    case SCENE_TAG_HINTS:
      return {
        ...state,
        sceneTagHints: payload
      };
    case TOTAL_PAGES_SCENES:
      return {
        ...state,
        totalPages: payload
      };
    case COUNT_SCENES:
      return {
        ...state,
        count: payload
      };
    case PAGINATION_PARAMS_SCENES:
      return {
        ...state,
        paginationParams: payload
      };
    default:
      return {
        ...state
      };
  }
};
