import React from "react";
import classes from "./Table.module.scss";

const Table = (props) => {
  const { children } = props;

  return <table className={classes.table}>{children}</table>;
};

export default Table;
