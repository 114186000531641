import React from "react";
import BannersSample from "../components/BannersSample";
import { connect } from "react-redux";
import { createBanners, uploadBanner } from "../../../store/banners/action";
import { showModal } from "../../../store/modal/action";

const BannersCreate = (props) => {
  const { uploadBanner, createBanners, showModal } = props;

  return <BannersSample uploadBanner={uploadBanner} createBanners={createBanners} showModal={showModal} />;
};

const mapDispatchToProps = {
  uploadBanner,
  createBanners,
  showModal
};

export default connect(null, mapDispatchToProps)(BannersCreate);
