import React from "react";
import classes from "./Dashboard.module.scss";
import { DashboardMenu } from "../../../utils/constants/dashboard.constants";
import DashboardItem from "./dashboard-item/DashboardItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logout } from "../../../store/users/action";

const Dashboard = (props) => {
  const { logout } = props;
  return (
    <div className={classes.dashboard}>
      <div className={classes.dashboard__wrap}>
        {DashboardMenu.map((element) => (
          <DashboardItem key={element.id} data={element} />
        ))}
        <div className={classes.logout}>
          <FontAwesomeIcon onClick={logout} icon={faSignOut} size="2x" />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logout
};

export default connect(null, mapDispatchToProps)(Dashboard);
