import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import EssencesSample from "../components/EssencesSample";
import {
  clearEssence,
  createEssenceTag,
  editEssences, essenceTagHintsGet,
  getCurrentEssence,
  removeEssenceTag
} from "../../../store/essences/action";

const EssencesEdit = (props) => {
  const {
    essenceCurrent,
    essenceTagHints,
    getCurrentEssence,
    editEssences,
    clearEssence,
    createEssenceTag,
    removeEssenceTag,
    essenceTagHintsGet
  } = props;
  const params = useParams();
  return (
    <EssencesSample
      id={params.id}
      getCurrentEssence={getCurrentEssence}
      editEssences={editEssences}
      clearEssence={clearEssence}
      essenceCurrent={essenceCurrent}
      createTag={createEssenceTag}
      removeTag={removeEssenceTag}
      essenceTagHintsGet={essenceTagHintsGet}
      essenceTagHints={essenceTagHints}
    />
  );
};

const mapStateToProps = (state) => ({
  essenceCurrent: state.essences.essenceCurrent,
  essenceTagHints: state.essences.essenceTagHints
});

const mapDispatchToProps = {
  getCurrentEssence,
  editEssences,
  clearEssence,
  createEssenceTag,
  removeEssenceTag,
  essenceTagHintsGet
};

export default connect(mapStateToProps, mapDispatchToProps)(EssencesEdit);
