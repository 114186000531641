import React from "react";
import styles from "./styles.module.scss";
import Table from "../../../../../components/layout/table/Table";
import ObjectsItem from "./ObjectsItem";

const Objects = (props) => {
  const { virtualObjects, selectedVirtualObjId, setSelectedVirtualObjId } = props;

  const handleCheck = (e) => {
    setSelectedVirtualObjId(+e.target.attributes[0].value);
  };

  return (
    <div className={styles["objects"]}>
      <h3>Виртуальные объекты</h3>
      <Table>
        <thead>
          <tr>
            <td></td>
            <td>Долгота</td>
            <td>Широта</td>
            <td>Размер</td>
            <td>Высота</td>
            <td>Баллы</td>
            <td>Тип объекта</td>
            <td>Объект</td>
          </tr>
        </thead>
        <tbody>
          {virtualObjects && virtualObjects.length
            ? virtualObjects.map((el) => (
                <ObjectsItem
                  key={el.id}
                  item={el}
                  selectedVirtualObjId={selectedVirtualObjId}
                  handleCheck={handleCheck}
                />
              ))
            : null}
        </tbody>
      </Table>
    </div>
  );
};

export default Objects;
