import { CREATE_TEAM, GET_TEAMS, REMOVE_TEAMS, EDIT_TEAMS } from "./actionType";

const initialState = {
  data: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TEAMS:
      return {
        ...state,
        data: payload
      };
    case CREATE_TEAM:
      return {
        ...state,
        data: payload
      };
    case REMOVE_TEAMS:
      return {
        ...state,
        data: payload
      };
    case EDIT_TEAMS:
      return {
        ...state,
        data: payload
      };
    default:
      return {
        ...state
      };
  }
};
