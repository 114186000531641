import React, { useEffect, useState } from "react";
import classes from "./Scenes.module.scss";
import { connect } from "react-redux";
import {
  changePaginationParamsScenes,
  deleteScene,
  getScenes,
  sceneTagHintsGet,
  cloneSceneAction
} from "../../store/scenes/action";
import { showModal } from "../../store/modal/action";
import Table from "../../components/layout/table/Table";
import CircleButton from "../../components/button/circle-button/CircleButton";
import { faClone, faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SCENES_CREATE_ROUTE, SCENES_ROUTE } from "../../utils/constants/routes.constants";
import Pagination from "../../components/layout/pagination/Pagination";
import { DOMAIN } from "../../utils/constants/domain.constans";
import { useNavigate } from "react-router-dom";
import TagSort from "../../components/hashtags/TagSort";

const Scenes = (props) => {
  const {
    getScenes,
    scenes,
    sceneTagHints,
    showModal,
    changePaginationParamsScenes,
    totalPages,
    paginationParams,
    count,
    deleteScene,
    sceneTagHintsGet,
    cloneSceneAction
  } = props;
  const navigate = useNavigate();

  const [sortTag, setSortTag] = useState("");

  useEffect(() => {
    if (sortTag) {
      changePaginationParamsScenes({
        pageSize: 999999,
        page: 0
      });
    }
    getScenes({ ...paginationParams, sortTag });
  }, [sortTag]);

  useEffect(() => {
    getScenes({ ...paginationParams, sortTag });
  }, [paginationParams]);

  const showQrCode = (e, title) => {
    showModal({
      title: title,
      children: `<img class=${classes.modal_img} src=${e.target.src} alt=${e.target.alt}/>`
    });
  };

  const editScenes = (id) => {
    navigate(`${SCENES_ROUTE}/${id}`);
  };

  const removeScene = async (id) => {
    deleteScene({ ...paginationParams, sortTag, id });
  };

  const cloneScene = async (el) => {
    cloneSceneAction(el);
  };

  if (!scenes.length) {
    return (
      <div>
        <div className={classes.scenes__tagSort}>
          <TagSort tagHints={sceneTagHints} tagHintsAction={sceneTagHintsGet} setSortTag={setSortTag} />
        </div>
        <div className={classes.empty}>
          Нет сцен
          <CircleButton path={SCENES_CREATE_ROUTE} className={classes["scenes__circle-button"]}>
            <FontAwesomeIcon icon={faPlus} size="2x" />
          </CircleButton>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.scenes}>
      <div className={classes.scenes__tagSort}>
        <TagSort tagHints={sceneTagHints} tagHintsAction={sceneTagHintsGet} setSortTag={setSortTag} />
      </div>
      <div className={classes.scenes__table_wrap}>
        <Table>
          <thead>
            <tr>
              <td>Название игры</td>
              <td>Время игры (сек.)</td>
              <td>Дистанция видимости объектов (см.)</td>
              <td>Максимальная дистанция для «захвата» объектов (см.)</td>
              <td>Кол-во объектов</td>
              <td>QRCode</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {scenes.length
              ? scenes.map((el) => (
                  <tr key={el.uuid}>
                    <td>{el.name_game}</td>
                    <td>{el.game_time ? el.game_time : `нет лимита`}</td>
                    <td>{el.visibility_distance}</td>
                    <td>{el.max_capture_distance}</td>
                    <td>{el.virtual_objects.length}</td>
                    <td>
                      <img
                        src={`${DOMAIN}/public/qrcode/${el.qr.name}`}
                        onClick={(e) => showQrCode(e, el.name_game)}
                        alt={el.qr.name}
                      />
                    </td>
                    <td className={classes.scenes__button_control}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        color={"#a8b2b5"}
                        onClick={() => editScenes(el.id)}
                        className={`${classes["scenes__control"]} ${classes["scenes__control--edit"]}`}
                        size="1x"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        color={"#a8b2b5"}
                        onClick={() => removeScene(el.id)}
                        className={`${classes["scenes__control"]} ${classes["scenes__control--remove"]}`}
                        size="1x"
                      />
                      <FontAwesomeIcon
                        icon={faClone}
                        color={"#a8b2b5"}
                        onClick={() => cloneScene(el)}
                        className={`${classes["scenes__control"]} ${classes["scenes__control--clone"]}`}
                        size="1x"
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
      {scenes.length ? (
        <Pagination
          countPage={totalPages}
          countElements={count}
          action={changePaginationParamsScenes}
          defaultParams={paginationParams}
        />
      ) : null}
      <CircleButton path={SCENES_CREATE_ROUTE} className={classes["scenes__circle-button"]}>
        <FontAwesomeIcon icon={faPlus} size="2x" />
      </CircleButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  scenes: state.scenes.scenes,
  sceneTagHints: state.scenes.sceneTagHints,
  totalPages: state.scenes.totalPages,
  paginationParams: state.scenes.paginationParams,
  count: state.scenes.count
});

const mapDispatchToProps = {
  getScenes,
  showModal,
  changePaginationParamsScenes,
  deleteScene,
  sceneTagHintsGet,
  cloneSceneAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Scenes);
