export const TYPE_OBJECTS = [
  {
    value: "3D",
    label: "3D"
  },
  {
    value: "POPUP",
    label: "Попап"
  },
  {
    value: "COUPON",
    label: "Купон"
  },
  {
    value: "PAID",
    label: "Платный"
  },
  {
    value: "QUESTION",
    label: "Вопрос"
  },
  {
    value: "BEACON",
    label: "Маяк"
  }
];

export const COLOR_MARKER_OBJECT = [
  {
    value: "#00aeff",
    label: "Синий"
  },
  {
    value: "#000000",
    label: "Черный"
  },
  {
    value: "#9e9e9e",
    label: "Серый"
  },
  {
    value: "#ff0000",
    label: "Красный"
  },
  {
    value: "#8aad03",
    label: "Зеленый"
  },
  {
    value: "#ffff00",
    label: "Желтый"
  },
  {
    value: "#e100ff",
    label: "Фиолетовый"
  },
  {
    value: "#ff9800",
    label: "Оранжевый"
  }
];
