import React, { useEffect, useState } from "react";
import classes from "./BannersSample.module.scss";
import Input from "../../../components/form/input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { BANNERS_ROUTE } from "../../../utils/constants/routes.constants";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { DOMAIN } from "../../../utils/constants/domain.constans";

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const BannersSample = (props) => {
  const { id, clearBanners, getCurrentBanners, uploadBanner, editBanners, createBanners } = props;

  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [cropper, setCropper] = useState();

  const [file, setFile] = useState("");
  const [url, setUrl] = useState("");
  const [nameBanner, setNameBanner] = useState("");
  const [errorNameBanner, setErrorNameBanner] = useState("");
  const [banner, setBanner] = useState(null);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    (async () => {
      if (id) {
        const response = await getCurrentBanners(id);
        setBanner(response);
      }
    })();
  }, [id, getCurrentBanners]);

  useEffect(() => {
    if (banner) {
      setFile(
        banner.image
          ? {
              name: banner.image
            }
          : ""
      );
      setUrl(banner.url ? banner.url : "");
      setNameBanner(banner.name ? banner.name : "");
    }
  }, [banner]);

  const onClear = async () => {
    const response = await clearBanners(id);
    setBanner(response);
  };

  const onCreate = async () => {
    if (nameBanner.length < 1) {
      setErrorNameBanner("Вы не ввели название");
      return false;
    }

    let data;

    if (cropData) {
      const cropDataFile = dataURLtoFile(cropData, new Date());

      const formData = new FormData();

      formData.append("img", cropDataFile, cropDataFile.name);

      const fileName = await uploadBanner(formData);

      setFile({
        name: fileName
      });

      data = {
        url,
        name: nameBanner,
        fileName
      };
    } else {
      data = {
        url,
        name: nameBanner
      };
    }

    const bannerCreated = await createBanners(data);

    navigate(`${BANNERS_ROUTE}/${bannerCreated.id}`);
  };

  const onEdit = async () => {
    if (nameBanner.length < 1) {
      setErrorNameBanner("Вы не ввели название");
      return false;
    }

    let data;
    if (cropData) {
      const cropDataFile = dataURLtoFile(cropData, new Date());

      const formData = new FormData();

      formData.append("img", cropDataFile, cropDataFile.name);

      const fileName = await uploadBanner(formData);

      setFile({
        name: fileName
      });

      data = {
        id: banner.id,
        url,
        name: nameBanner,
        fileName
      };
    } else {
      data = {
        id: banner.id,
        url,
        name: nameBanner
      };
    }

    editBanners(data);
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setIsShow(true);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = async () => {
    setIsShow(false);
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const cancelCropData = () => {
    setIsShow(false);
  };

  return (
    <div className={classes["banners-sample"]}>
      <h2>Баннер</h2>
      {isShow ? (
        <div className={classes.cropper}>
          <Cropper
            style={{
              height: "90%",
              width: "100%"
            }}
            zoomTo={0.5}
            initialAspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            aspectRatio={2.37 / 1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
          <div className={classes.button_wrap}>
            <Button className={classes.button} onClick={cancelCropData}>
              Отменить
            </Button>
            <Button className={classes.button} onClick={getCropData}>
              Сохранить
            </Button>
          </div>
        </div>
      ) : null}
      <div className={classes["banners-sample__upload-box"]}>
        {file ? (
          <div className={classes["banners-sample__wrap-img"]}>
            {!cropData ? (
              <img src={`${DOMAIN}/public/banners/${file.name}`} alt={file.name} />
            ) : (
              <img src={`${cropData}`} alt={""} />
            )}
            <Input type={"file"} name={"upload"} onChange={onChange} />
          </div>
        ) : (
          <div className={classes["banners-sample__wrap-img"]}>
            {!cropData ? (
              <FontAwesomeIcon icon={faFileImage} color={"#a8b2b5"} size="8x" />
            ) : (
              <img src={`${cropData}`} alt={""} />
            )}
            <Input type={"file"} name={"upload"} onChange={onChange} />
          </div>
        )}
      </div>
      <div className={classes["banners-sample__wrap_inp"]}>
        <Input
          type={"text"}
          name={"name"}
          classNameInput={classes["banners-sample__wrap_inp--input"]}
          placeholder={"Введите название"}
          value={nameBanner}
          error={errorNameBanner}
          onChange={(e) => setNameBanner(e.target.value)}
        />
      </div>
      <div className={classes["banners-sample__wrap_inp"]}>
        <Input
          type={"text"}
          name={"url"}
          classNameInput={classes["banners-sample__wrap_inp--input"]}
          placeholder={"Введите ссылку"}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      <div className={classes["banners-sample__buttons"]}>
        {!banner ? (
          <Button className={classes["banners-sample__buttons--button"]} onClick={onCreate}>
            Сохранить
          </Button>
        ) : (
          <Button className={classes["banners-sample__buttons--button"]} onClick={onEdit}>
            Изменить
          </Button>
        )}
        <Button className={classes["banners-sample__buttons--button"]} disabled={!banner} onClick={onClear}>
          Очистить
        </Button>
      </div>
    </div>
  );
};

export default BannersSample;
