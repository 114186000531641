import React, { useState, useEffect } from "react";
import classes from "./TeamsStatistic.module.scss";
import { useParams } from "react-router-dom";
import LoaderComponent from "../../../components/layout/loader/LoaderComponent";
import Table from "../../../components/layout/table/Table";
import { connect } from "react-redux";
import { getTeamsStatistic } from "../../../store/statistic/team-statistic/action";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const teamsStatisticField = [
  {
    id: 1,
    sortKey: "team",
    text: "Команда"
  },
  {
    id: 2,
    sortKey: "points",
    text: "Очков"
  }
];

const TeamsStatistic = (props) => {
  const { team_statistic, getTeamsStatistic } = props;
  const params = useParams();
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState(null);
  const [activeId, setActiveId] = useState();

  useEffect(() => {
    getTeamsStatistic(params.id);
  }, []);

  useEffect(() => {
    if (sortConfig) {
      getTeamsStatistic(params.id, sortConfig);
    }
  }, [sortConfig]);

  const requestSort = (id, key) => {
    setActiveId(id);
    let direction = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({
      key,
      direction
    });
  };

  if (!params.id) {
    return (
      <div className="loader">
        <LoaderComponent
          size={100}
          thickness={100}
          speed={50}
          style={{
            position: "absolute",
            transform: "translate(50%, 50%)",
            right: "50%",
            bottom: "50%",
            color: "#a90707"
          }}
        />
      </div>
    );
  }

  return (
    <div className={classes["teams-statistic"]}>
      <div className={classes["teams-statistic__table_wrap"]}>
        <div className={classes["teams-statistic__button_teams"]} onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} color={"#000000"} size="1x" />
          <span>Назад</span>
        </div>
        <Table>
          <thead>
            <tr>
              {teamsStatisticField.map((element) => (
                <td
                  key={element.id}
                  onClick={() => requestSort(element.id, element.sortKey)}
                  className={
                    activeId === element.id
                      ? `${classes[sortConfig.direction]} ${classes["teams-statistic_field"]}`
                      : `${classes["teams-statistic_field"]}`
                  }>
                  {element.text}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {team_statistic
              ? team_statistic.map((el) => {
                  return (
                    <tr key={el.id}>
                      <td>{el.name_team}</td>
                      <td>{el.points}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team_statistic: state.statistic.team_statistic.data
});

const mapDispatchToProps = {
  getTeamsStatistic
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatistic);
