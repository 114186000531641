import React, { useEffect, useState } from "react";
import classes from "./ColorBox.module.scss";
import { SketchPicker } from "react-color";

const ColorBox = (props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const onColor = (e) => {
    props.onHandleColor(e);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <div className={classes.colorBox}>
      <div className={classes.swatch} onClick={handleClick}>
        <div className={classes.color} style={{ background: `${props.color}` }} />
      </div>
      {displayColorPicker ? (
        <div style={classes.popover}>
          <div style={classes.cover} onClick={handleClose} />
          <SketchPicker color={props.color} onChange={(e) => onColor(e.hex)} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorBox;
