import { combineReducers } from "redux";
import gameStatisticReducer from "./game-statistic/gameStatisticReducer";
import userGameStatisticReducer from "./user-game-statistic/userGameStatisticReducer";
import teamStatisticReducer from "./team-statistic/teamStatisticReducer";

export const statisticReducer = combineReducers({
  game: gameStatisticReducer,
  user_game: userGameStatisticReducer,
  team_statistic: teamStatisticReducer
});
