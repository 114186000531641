import React from "react";
import styles from "./styles.module.scss";
import Table from "../../../../../components/layout/table/Table";
import BeaconChildrenItem from "./BeaconChildrenItem";

const BeaconChildren = (props) => {
  const { dragItem, dragOverItem, beaconChildren, selectedBeaconChildrenId, setSelectedBeaconChildrenId, onDragEnd } =
    props;
  const handleCheck = (e) => {
    setSelectedBeaconChildrenId(+e.target.attributes[0].value);
  };

  return (
    <div className={styles.beaconChildren}>
      <h3>Последовательные маяки</h3>
      <Table>
        <thead>
          <tr>
            <td></td>
            <td>Sort</td>
            <td>Долгота</td>
            <td>Широта</td>
            <td>Размер</td>
            <td>Высота</td>
            <td>Объект</td>
          </tr>
        </thead>
        <tbody>
          {beaconChildren && beaconChildren.length
            ? beaconChildren.map((el) => (
                <BeaconChildrenItem
                  dragItem={dragItem}
                  dragOverItem={dragOverItem}
                  key={el.id}
                  item={el}
                  selectedBeaconChildrenId={selectedBeaconChildrenId}
                  handleCheck={handleCheck}
                  onDragEnd={onDragEnd}
                />
              ))
            : null}
        </tbody>
      </Table>
    </div>
  );
};

export default BeaconChildren;
