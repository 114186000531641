export const GET_ESSENCES = "GET_ESSENCES";
export const GET_ESSENCES_CURRENT = "GET_ESSENCES_CURRENT";
export const CLEAR_ESSENCES_CURRENT = "CLEAR_ESSENCES_CURRENT";
export const EDIT_ESSENCES = "EDIT_ESSENCES";
export const PAGINATION_PARAMS_ESSENCES = "PAGINATION_PARAMS_ESSENCES";
export const TOTAL_PAGES_ESSENCES = "TOTAL_PAGES_ESSENCES";
export const COUNT_ESSENCES = "COUNT_ESSENCES";
export const CREATE_ESSENCE_TAG = "CREATE_ESSENCE_TAG";
export const REMOVE_ESSENCE_TAG = "REMOVE_ESSENCE_TAG";
export const ESSENCE_TAG_HINTS = "ESSENCE_TAG_HINTS";
export const ESSENCE_CLONE = "ESSENCE_CLONE";
