import React from "react";
import classes from "./Button.module.scss";

const Button = (props) => {
  const { children, onClick, className, type, disabled } = props;
  return (
    <button
      disabled={disabled}
      type={type}
      className={
        className
          ? disabled
            ? `${classes.button} ${className} ${classes.button__disabled}`
            : `${classes.button} ${className}`
          : disabled
          ? `${classes.button} ${classes.button__disabled}`
          : classes.button
      }
      onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
