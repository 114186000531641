import { Api } from "../../../services/api";
import { Resource } from "../../../services/ApiResources";
import { GET_SCENE_TEAM_STATISTIC } from "./actionType";

export const getTeamsStatistic = (sceneId, sortConfig) => {
  return async (dispatch) => {
    try {
      let param = {
        sceneId
      };
      if (sortConfig)
        param = {
          ...param,
          sortKey: sortConfig.key,
          direction: sortConfig.direction
        };

      const response = await Api.GET(
        {
          url: Resource.STATISTIC_TEAMS
        },
        param
      );

      if (response) {
        dispatch({
          type: GET_SCENE_TEAM_STATISTIC,
          payload: response
        });
      }
    } catch (e) {
      dispatch({
        type: GET_SCENE_TEAM_STATISTIC,
        payload: null
      });
      console.log(e);
    }
  };
};
