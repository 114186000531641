import React from "react";
import styles from "./styles.module.scss";

const TagHintItem = (props) => {
  const { item, handleChangeHint } = props;
  const onClick = () => {
    handleChangeHint(item.name);
  };

  return (
    <div className={styles.tagHintItem} onClick={onClick}>
      <span>{item.name}</span>
    </div>
  );
};

export default TagHintItem;
