import { Api } from "../../services/api";
import { Resource } from "../../services/ApiResources";
import {
  GET_SCENES,
  COUNT_SCENES,
  PAGINATION_PARAMS_SCENES,
  TOTAL_PAGES_SCENES,
  GET_SCENE_CURRENT,
  CREATE_SCENE_TAG,
  REMOVE_SCENE_TAG,
  SCENE_TAG_HINTS,
  SCENE_CLONE
} from "./actionType";
import { ERROR, SUCCESS } from "../status/actionType";
import { confirmAlert } from "react-confirm-alert";

export const getScenes = (params) => {
  return async (dispatch) => {
    try {
      const response = await Api.GET(
        {
          url: Resource.SCENES
        },
        params
      );
      if (response) {
        dispatch({
          type: TOTAL_PAGES_SCENES,
          payload: response.totalPages
        });
        dispatch({
          type: COUNT_SCENES,
          payload: response.scenes.count
        });
        dispatch({
          type: GET_SCENES,
          payload: response.scenes.rows
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const changePaginationParamsScenes = (paginationParams) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_PARAMS_SCENES,
      payload: paginationParams
    });
  };
};

export const getSceneCurrent = (id) => {
  return async (dispatch) => {
    try {
      const res = await Api.GET({
        id,
        url: Resource.SCENES
      });

      if (res) {
        dispatch({
          type: GET_SCENE_CURRENT,
          payload: res
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const createScene = (data) => {
  return async (dispatch) => {
    try {
      const res = await Api.POST(Resource.SCENES, data);
      if (res) {
        dispatch({
          type: SUCCESS,
          payload: res.success
        });
      }

      return res.scene;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const editScene = (data, id) => {
  return async (dispatch) => {
    try {
      const res = await Api.PUT(`${Resource.SCENES_EDIT}/${id}`, data);
      if (res)
        dispatch({
          type: SUCCESS,
          payload: res.success
        });

      return res.scene;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const deleteScene = (params) => {
  return async (dispatch) => {
    try {
      confirmAlert({
        title: "Подтверждение",
        message: "Удалить сцену?",
        buttons: [
          {
            label: "Да",
            onClick: async () => {
              const res = await Api.DELETE(Resource.SCENES, params.id);
              if (res) {
                dispatch({
                  type: SUCCESS,
                  payload: res.success
                });
              }

              const response = await Api.GET(
                {
                  url: Resource.SCENES
                },
                params
              );

              if (response) {
                dispatch({
                  type: TOTAL_PAGES_SCENES,
                  payload: response.totalPages
                });
                dispatch({
                  type: COUNT_SCENES,
                  payload: response.scenes.count
                });
                dispatch({
                  type: GET_SCENES,
                  payload: response.scenes.rows
                });
              }
            }
          },
          {
            label: "Нет"
          }
        ]
      });
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const changeTeamGame = (isTeam, id) => {
  return async (dispatch) => {
    try {
      await Api.PUT(`${Resource.SCENES_CHANGE_TEAM_GAME}/${id}`, {
        isTeam
      });
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const createSceneTag = (data) => {
  return async (dispatch) => {
    try {
      const res = await Api.POST(Resource.TAGS, { ...data, type: "SCENE" });
      if (res) {
        dispatch({
          type: CREATE_SCENE_TAG,
          payload: res
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const removeSceneTag = (id, objectId) => {
  return async (dispatch) => {
    try {
      const res = await Api.DELETE(Resource.TAGS, id, { type: "SCENE", objectId });
      if (res) {
        dispatch({
          type: REMOVE_SCENE_TAG,
          payload: id
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const sceneTagHintsGet = (query) => {
  return async (dispatch) => {
    try {
      const res = await Api.GET({ url: Resource.TAG_HINTS }, { type: "SCENE", query });
      if (res) {
        dispatch({
          type: SCENE_TAG_HINTS,
          payload: res
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const cloneSceneAction = (data) => {
  return async (dispatch) => {
    try {
      confirmAlert({
        title: "Подтверждение",
        message: "Клонировать сцену?",
        buttons: [
          {
            label: "Да",
            onClick: async () => {
              const res = await Api.POST(`${Resource.SCENES}/${data.id}/clone`, data);

              if (res) {
                dispatch({
                  type: SUCCESS,
                  payload: res.success
                });
                dispatch({
                  type: SCENE_CLONE,
                  payload: res.scene
                });
              }
            }
          },
          {
            label: "Нет"
          }
        ]
      });
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};
