import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TagHintItem from "./TagHintItem";

const TagSort = (props) => {
  const { tagHints, tagHintsAction, setSortTag } = props;

  const [tag, setTag] = useState("");
  const [isHintChanged, setIsHintChanged] = useState(false);

  useEffect(() => {
    setIsHintChanged(false);
    if (tag.length >= 3) {
      const timeOutId = setTimeout(() => {
        setSortTag(tag);
        tagHintsAction(tag);
      }, 500);
      return () => clearTimeout(timeOutId);
    } else {
      setSortTag("");
      tagHintsAction(null);
    }
  }, [tag]);

  useEffect(() => {
    if (tagHints) {
      const tagHintsFound = tagHints.find((item) => item.name.toUpperCase() === tag.toUpperCase());
      if (tagHintsFound) setIsHintChanged(true);
    }
  }, [tagHints]);

  return (
    <>
      <p className={styles.tagSortLabel}>Сортировка по тегам</p>
      <input
        name={"tags"}
        type="text"
        autoComplete={"off"}
        placeholder={"Введите тег"}
        value={tag}
        onChange={(e) => setTag(e.target.value)}
        className={styles.tagSortInput}
        required={true}
      />
      {tagHints && tagHints.length && !isHintChanged ? (
        <div className={styles.tagsHint}>
          {tagHints
            ? tagHints.map((item) => <TagHintItem key={item.id} item={item} handleChangeHint={setTag} />)
            : null}
        </div>
      ) : null}
    </>
  );
};

export default TagSort;
