import { ERROR, SUCCESS } from "./actionType";

const initialState = {
  error: [],
  success: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ERROR:
      return {
        ...state,
        error: [...state.error, payload]
      };
    case SUCCESS:
      return {
        ...state,
        success: [...state.success, payload]
      };
    default:
      return {
        ...state
      };
  }
};
