import React from "react";
import styles from "./styles.module.scss";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/form/input/Input";
import Select from "react-select";
import { COLOR_MARKER_OBJECT, TYPE_OBJECTS } from "../../../../../utils/constants/objects.constants";
import { Switch } from "pretty-checkbox-react";
import Form from "../../../../../components/form/Form";
import TagSort from "../../../../../components/hashtags/TagSort";

const customStyles = {
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    height: "14px",
    width: "14px",
    alignItems: "center"
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none"
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0
  }),
  control: (provided) => ({
    ...provided,
    "borderRadius": "0px",
    "border": 0,
    "borderBottom": "solid",
    "borderBottomWidth": "1px",
    "fontSize": "12px",
    "width": "100%",
    "minHeight": "auto",
    "boxShadow": "none",
    "&:hover": {
      borderColor: "none"
    }
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: "12px",
    maxHeight: "200px"
  }),
  option: (provided) => ({
    ...provided,
    padding: "5px"
  })
};

const VirtualObjectForm = (props) => {
  const {
    essenceTagHints,
    selectedVirtualObjId,
    virtualObject,
    typeObject,
    essenceOptions,
    essenceSelected,
    errorEssence,
    isConsistent,
    onDuplicate,
    onDelete,
    onClear,
    onEdit,
    setTypeObject,
    setEssenceSelected,
    onEssenceSelected,
    setVirtualObject,
    setIsConsistent,
    onChangeConsistent,
    essenceTagHintsGet,
    setSortTag
  } = props;

  return (
    <Form className={styles["virtual-objects-form"]}>
      <div className={styles["virtual-objects-form__tagSort"]}>
        <TagSort tagHints={essenceTagHints} tagHintsAction={essenceTagHintsGet} setSortTag={setSortTag} />
      </div>
      <div className={styles["virtual-objects-form__buttons"]}>
        <Button className={styles["virtual-objects-form__buttons--double"]} onClick={onDuplicate}>
          Дублировать
        </Button>
        <Button className={styles["virtual-objects-form__buttons--edit"]} onClick={onEdit}>
          Изменить
        </Button>
        <Button className={styles["virtual-objects-form__buttons--remove"]} onClick={onDelete}>
          Удалить
        </Button>
        <Button onClick={onClear}>Очистить сцену</Button>
      </div>
      <div className={styles["virtual-objects-form__fields"]}>
        <Input
          type={"number"}
          name={"longitude"}
          classNameLabel={styles["virtual-objects-form__fields--label"]}
          classNameWrap={styles["virtual-objects-form__fields--wrap"]}
          classNameInput={styles["virtual-objects-form__fields--input"]}
          label={"Долгота"}
          value={virtualObject.longitude}
          onChange={(e) => setVirtualObject((data) => ({ ...data, longitude: e.target.value }))}
        />
        <Input
          type={"number"}
          name={"latitude"}
          classNameLabel={styles["virtual-objects-form__fields--label"]}
          classNameWrap={styles["virtual-objects-form__fields--wrap"]}
          classNameInput={styles["virtual-objects-form__fields--input"]}
          label={"Широта"}
          value={virtualObject.latitude}
          onChange={(e) => setVirtualObject((data) => ({ ...data, latitude: e.target.value }))}
        />
        <Input
          type={"number"}
          name={"size"}
          classNameLabel={styles["virtual-objects-form__fields--label"]}
          classNameWrap={styles["virtual-objects-form__fields--wrap"]}
          classNameInput={styles["virtual-objects-form__fields--input"]}
          label={"Размер (см)"}
          value={virtualObject.size}
          onChange={(e) =>
            setVirtualObject((data) => ({
              ...data,
              size: e.target.value.length <= 6 ? e.target.value : virtualObject.size
            }))
          }
        />
        <Input
          type={"number"}
          name={"height"}
          classNameLabel={styles["virtual-objects-form__fields--label"]}
          classNameWrap={styles["virtual-objects-form__fields--wrap"]}
          classNameInput={styles["virtual-objects-form__fields--input"]}
          label={"Высота (см)"}
          value={virtualObject.height}
          onChange={(e) =>
            setVirtualObject((data) => ({
              ...data,
              height: e.target.value.length <= 6 ? e.target.value : virtualObject.height
            }))
          }
        />
        {typeObject === "3D" ? (
          <Input
            type={"number"}
            name={"points"}
            classNameLabel={styles["virtual-objects-form__fields--label"]}
            classNameWrap={styles["virtual-objects-form__fields--wrap"]}
            classNameInput={styles["virtual-objects-form__fields--input"]}
            label={"Баллы"}
            value={virtualObject.points}
            onChange={(e) =>
              setVirtualObject((data) => ({
                ...data,
                points: e.target.value.length <= 6 ? e.target.value : virtualObject.points
              }))
            }
          />
        ) : null}
        <div className={styles["virtual-objects-form__select_wrap"]}>
          <p>Тип объекта</p>
          <Select
            options={TYPE_OBJECTS}
            styles={customStyles}
            onChange={(e) => {
              setEssenceSelected(null);
              setTypeObject(e.value);
            }}
            value={TYPE_OBJECTS.filter((option) => option.value === typeObject)}
          />
        </div>
        <div className={styles["virtual-objects-form__select_wrap"]}>
          <p>Объект</p>
          <Select
            options={essenceOptions.filter((option) => option.type === typeObject)}
            styles={customStyles}
            onChange={(e) => onEssenceSelected(e.value)}
            placeholder={"Выберите объект"}
            value={essenceOptions.filter((option) => option.value === essenceSelected)}
          />
          {errorEssence && <p className={styles["virtual-objects-form__select_wrap--error"]}>{errorEssence}</p>}
        </div>
        <div className={styles["virtual-objects-form__select_wrap"]}>
          <p>Цвет маркера</p>
          <Select
            options={COLOR_MARKER_OBJECT}
            styles={customStyles}
            onChange={(e) => setVirtualObject((data) => ({ ...data, mark_color: e.value }))}
            value={COLOR_MARKER_OBJECT.filter((option) => option.value === virtualObject.mark_color)}
          />
        </div>
      </div>
      {typeObject === "BEACON" && selectedVirtualObjId ? (
        <div className={styles["virtual-objects-form__select_wrap"]}>
          <Switch
            state={isConsistent}
            setState={setIsConsistent}
            onChange={(e) => onChangeConsistent(e.target.checked)}>
            {!isConsistent ? "видны все сразу" : "собирать последовательно"}
          </Switch>
        </div>
      ) : null}
    </Form>
  );
};

export default VirtualObjectForm;
