import {
  CREATE_VIRTUAL_OBJECT,
  CLEAR_LIST_VIRTUAL_OBJECT,
  GET_VIRTUAL_OBJECT,
  DELETE_VIRTUAL_OBJECT,
  EDIT_VIRTUAL_OBJECT,
  CREATE_BEACON_CHILDREN,
  TOOGLE_CONSISTENT,
  EDIT_BEACON_CHILDREN,
  DRAG_AND_DROP_BEACON_CHILDREN, DELETE_BEACON_CHILDREN
} from "./actionType";

const initialState = {
  virtual_objects: null,
  result: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VIRTUAL_OBJECT:
      return {
        ...state,
        virtual_objects: payload
      };
    case CLEAR_LIST_VIRTUAL_OBJECT:
      return {
        ...state,
        virtual_objects: payload
      };
    case CREATE_VIRTUAL_OBJECT:
      return {
        ...state,
        virtual_objects: payload
      };
    case CREATE_BEACON_CHILDREN:
      return {
        ...state,
        virtual_objects: [...payload]
      };
    case EDIT_BEACON_CHILDREN:
      return {
        ...state,
        virtual_objects: [...payload]
      };
    case DRAG_AND_DROP_BEACON_CHILDREN:
      return {
        ...state,
        virtual_objects: [...payload]
      };
    case DELETE_BEACON_CHILDREN:
      return {
        ...state,
        virtual_objects: [...payload]
      };
    case TOOGLE_CONSISTENT:
      return {
        ...state,
        virtual_objects: [...payload]
      };
    case DELETE_VIRTUAL_OBJECT:
      return {
        ...state,
        virtual_objects: payload
      };
    case EDIT_VIRTUAL_OBJECT:
      return {
        ...state,
        virtual_objects: payload
      };
    default:
      return {
        ...state
      };
  }
};
