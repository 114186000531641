import { Api } from "../../services/api";
import { Resource } from "../../services/ApiResources";
import { ERROR } from "../status/actionType";
import { GET_TEAMS, CREATE_TEAM, REMOVE_TEAMS, EDIT_TEAMS } from "./actionType";

export const createTeam = (data) => {
  return async (dispatch, getState) => {
    try {
      const teamState = getState().teams.data;

      const res = await Api.POST(Resource.TEAMS, data);

      if (res) {
        const newTeams = [...teamState, res];
        dispatch({
          type: CREATE_TEAM,
          payload: newTeams
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const getTeamsScene = (sceneId) => {
  return async (dispatch) => {
    try {
      const res = await Api.GET({
        id: sceneId,
        url: Resource.TEAMS
      });
      if (res)
        dispatch({
          type: GET_TEAMS,
          payload: res.teams
        });
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const removeTeam = (id) => {
  return async (dispatch, getState) => {
    try {
      const teamState = getState().teams.data;

      const res = await Api.DELETE(Resource.TEAMS, id);

      if (res) {
        const newTeams = teamState.filter((el) => el.id !== +res.team_id);
        dispatch({
          type: REMOVE_TEAMS,
          payload: newTeams
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const editTeam = (data) => {
  return async (dispatch, getState) => {
    try {
      const teamState = getState().teams.data;

      const res = await Api.PUT(`${Resource.TEAMS}/${data.id}`, data);

      if (res) {
        const newTeams = teamState.map((el) => {
          return el.id === res.id
            ? {
                id: res.id,
                name_team: res.name_team,
                color: res.color
              }
            : el;
        });
        dispatch({
          type: EDIT_TEAMS,
          payload: newTeams
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};
