import {
  GET_ESSENCES,
  EDIT_ESSENCES,
  COUNT_ESSENCES,
  TOTAL_PAGES_ESSENCES,
  PAGINATION_PARAMS_ESSENCES,
  GET_ESSENCES_CURRENT,
  CREATE_ESSENCE_TAG,
  CLEAR_ESSENCES_CURRENT,
  REMOVE_ESSENCE_TAG,
  ESSENCE_TAG_HINTS,
  ESSENCE_CLONE
} from "./actionType";

const initialState = {
  data: null,
  essenceCurrent: {},
  essenceTagHints: null,
  paginationParams: {
    page: 0,
    pageSize: 10
  },
  totalPages: null,
  count: null
};

const essencesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ESSENCE_CLONE: {
      const addedEssence = [payload, ...state.data];
      if (addedEssence.length > state.paginationParams.pageSize) {
        addedEssence.pop();
      }

      const newTotalPages = Math.ceil((state.count + 1) / state.paginationParams.pageSize);
      return {
        ...state,
        data: addedEssence,
        count: state.count + 1,
        totalPages: newTotalPages
      };
    }
    case GET_ESSENCES_CURRENT:
      return {
        ...state,
        essenceCurrent: payload
      };
    case CREATE_ESSENCE_TAG:
      state.essenceCurrent.tags = [...state.essenceCurrent.tags, payload];
      return {
        ...state,
        essenceCurrent: { ...state.essenceCurrent }
      };
    case ESSENCE_TAG_HINTS:
      return {
        ...state,
        essenceTagHints: payload
      };
    case GET_ESSENCES:
      return {
        ...state,
        data: payload
      };
    case REMOVE_ESSENCE_TAG: {
      const essenceFiltered = state.essenceCurrent.tags.filter((item) => item.id !== payload);
      state.essenceCurrent.tags = [...essenceFiltered];

      return {
        ...state,
        essenceCurrent: { ...state.essenceCurrent }
      };
    }
    case CLEAR_ESSENCES_CURRENT:
      return {
        ...state,
        essenceCurrent: payload
      };
    case EDIT_ESSENCES:
      return {
        ...state,
        data: payload
      };
    case TOTAL_PAGES_ESSENCES:
      return {
        ...state,
        totalPages: payload
      };
    case COUNT_ESSENCES:
      return {
        ...state,
        count: payload
      };
    case PAGINATION_PARAMS_ESSENCES:
      return {
        ...state,
        paginationParams: payload
      };
    default:
      return {
        ...state
      };
  }
};

export default essencesReducer;
