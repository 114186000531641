import {
  GET_USER_GAME_STATISTIC,
  TOTAL_PAGES_USER_GAME_STATISTIC,
  PAGINATION_PARAMS_USER_GAME_STATISTIC,
  COUNT_USER_GAME_STATISTIC,
  GET_USER_GAMES,
  RESET_USER_GAMES
} from "./actionType";

const initialState = {
  user_game_statistic: null,
  user_games: null,
  paginationParams: {
    page: 0,
    pageSize: 10
  },
  totalPages: null,
  count: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_GAMES:
      return {
        ...state,
        user_games: payload
      };
    case RESET_USER_GAMES:
      return {
        ...state,
        user_games: [...payload]
      };
    case GET_USER_GAME_STATISTIC:
      return {
        ...state,
        user_game_statistic: payload
      };
    case TOTAL_PAGES_USER_GAME_STATISTIC:
      return {
        ...state,
        totalPages: payload
      };
    case COUNT_USER_GAME_STATISTIC:
      return {
        ...state,
        count: payload
      };
    case PAGINATION_PARAMS_USER_GAME_STATISTIC:
      return {
        ...state,
        paginationParams: payload
      };
    default:
      return {
        ...state
      };
  }
};
