export const GET_VIRTUAL_OBJECT = "GET_VIRTUAL_OBJECT";
export const CLEAR_LIST_VIRTUAL_OBJECT = "CLEAR_LIST_VIRTUAL_OBJECT";
export const CREATE_VIRTUAL_OBJECT = "CREATE_VIRTUAL_OBJECT";
export const DELETE_VIRTUAL_OBJECT = "DELETE_VIRTUAL_OBJECT";
export const DELETE_BEACON_CHILDREN = "DELETE_BEACON_CHILDREN";
export const EDIT_VIRTUAL_OBJECT = "EDIT_VIRTUAL_OBJECT";
export const CREATE_BEACON_CHILDREN = "CREATE_BEACON_CHILDREN";
export const EDIT_BEACON_CHILDREN = "EDIT_BEACON_CHILDREN";
export const DRAG_AND_DROP_BEACON_CHILDREN = "DRAG_AND_DROP_BEACON_CHILDREN";
export const TOOGLE_CONSISTENT = "TOOGLE_CONSISTENT";
