import React from "react";
import BannersSample from "../components/BannersSample";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { clearBanners, getCurrentBanners, uploadBanner, editBanners } from "../../../store/banners/action";
import { showModal } from "../../../store/modal/action";

const BannersEdit = (props) => {
  const { getCurrentBanners, clearBanners, uploadBanner, editBanners, showModal } = props;
  const params = useParams();

  return (
    <BannersSample
      id={params.id}
      uploadBanner={uploadBanner}
      clearBanners={clearBanners}
      getCurrentBanners={getCurrentBanners}
      editBanners={editBanners}
      showModal={showModal}
    />
  );
};

const mapDispatchToProps = {
  uploadBanner,
  getCurrentBanners,
  clearBanners,
  editBanners,
  showModal
};

export default connect(null, mapDispatchToProps)(BannersEdit);
