import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TagItem from "./TagItem";
import TagHintItem from "./TagHintItem";

const Tags = (props) => {
  const { tags, tagHints, addTag, objectId, removeTag, tagHintsAction } = props;
  const [tag, setTag] = useState("");
  const [isHintChanged, setIsHintChanged] = useState(false);

  useEffect(() => {
    setIsHintChanged(false);
    if (tag.length >= 3) {
      const timeOutId = setTimeout(() => tagHintsAction(tag), 500);
      return () => clearTimeout(timeOutId);
    } else {
      tagHintsAction(null);
    }
  }, [tag]);

  useEffect(() => {
    if (tagHints) {
      const tagHintsFound = tagHints.find((item) => item.name.toUpperCase() === tag.toUpperCase());
      if (tagHintsFound) setIsHintChanged(true);
    }
  }, [tagHints]);

  const submit = (e) => {
    e.preventDefault();
    if (!tag.length) {
      return;
    }

    const data = {
      name: tag,
      objectId
    };
    addTag(data);
    setTag("");
  };

  const onRemove = (id) => {
    removeTag(id, objectId);
  };

  return (
    <div className={styles.tags}>
      <p className={styles.tagsLabel}>Теги</p>
      <form className={styles.tagsForm} onSubmit={submit}>
        <input
          name={"tags"}
          type="text"
          autoComplete={"off"}
          placeholder={"Введите тег"}
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          className={styles.tagsInput}
        />
        <button type={"submit"} className={styles.tagsButton}>
          +
        </button>
      </form>
      {tags && tags.length ? tags.map((item) => <TagItem key={item.id} item={item} onRemove={onRemove} />) : null}
      {tagHints && tagHints.length && !isHintChanged ? (
        <div className={styles.tagsHint}>
          {tagHints
            ? tagHints.map((item) => <TagHintItem key={item.id} item={item} handleChangeHint={setTag} />)
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default Tags;
