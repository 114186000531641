import { SET_IS_AUTH, LOGOUT, GET_USERS, PAGINATION_PARAMS_USERS, COUNT_USERS, TOTAL_PAGES_USERS } from "./actionType";

const initialState = {
  isAuth: false,
  users: null,
  paginationParams: {
    page: 0,
    pageSize: 10
  },
  totalPages: null,
  count: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: payload
      };
    case LOGOUT:
      return {
        ...state,
        currentUser: {},
        isAuth: false
      };
    case GET_USERS:
      return {
        ...state,
        users: payload
      };
    case TOTAL_PAGES_USERS:
      return {
        ...state,
        totalPages: payload
      };
    case COUNT_USERS:
      return {
        ...state,
        count: payload
      };
    case PAGINATION_PARAMS_USERS:
      return {
        ...state,
        paginationParams: payload
      };
    default:
      return {
        ...state
      };
  }
};
