import React from "react";
import Checkbox from "../../../../../components/form/checkbox/Checkbox";
import styles from "./styles.module.scss";

const BeaconChildrenItem = (props) => {
  const { dragItem, dragOverItem, item, selectedBeaconChildrenId, handleCheck, onDragEnd } = props;

  return (
    <tr
      key={item.id}
      draggable
      onDragStart={() => (dragItem.current = item.sort)}
      onDragEnter={() => (dragOverItem.current = item.sort)}
      onDragEnd={onDragEnd}
      onDragOver={(e) => e.preventDefault()}>
      <td>
        <Checkbox
          checked={selectedBeaconChildrenId === item.id && true}
          name={"virtual-object"}
          type={"checkbox"}
          idElement={item.id}
          onChange={handleCheck}
          classNameCheckmark={styles.beaconChildren__checkbox}
        />
      </td>
      <td>{item.sort}</td>
      <td>
        {item.longitude}
        {item.id}
      </td>
      <td>{item.latitude}</td>
      <td>{item.size}</td>
      <td>{item.height}</td>
      <td>{item.essence ? item.essence.name : null}</td>
    </tr>
  );
};

export default BeaconChildrenItem;
