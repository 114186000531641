import React, { useEffect, useState } from "react";
import classes from "./userGameStatistics.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  changePaginationParamsUserGameStatistic,
  getUserGameStatistic,
  toggleShowStatisticGames
} from "../../../store/statistic/user-game-statistic/action";
import LoaderComponent from "../../../components/layout/loader/LoaderComponent";
import Table from "../../../components/layout/table/Table";
import Pagination from "../../../components/layout/pagination/Pagination";
import { formattedDate } from "../../../helpers/dateHelper";
import Button from "../../../components/button/Button";
import { STATISTIC_ROUTE } from "../../../utils/constants/routes.constants";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const statisticField = [
  {
    id: 1,
    sortKey: "user.last_name",
    text: "ФИО"
  },
  {
    id: 2,
    sortKey: "user.phone",
    text: "Телефон"
  },
  {
    id: 3,
    sortKey: "user.email",
    text: "Почта"
  },
  {
    id: 4,
    sortKey: "objects_collected",
    text: "Собрано"
  },
  {
    id: 5,
    sortKey: "time",
    text: "Время"
  },
  {
    id: 6,
    sortKey: "points",
    text: "Очков"
  },
  {
    id: 7,
    sortKey: "team",
    text: "Команда"
  }
];

const UserGameStatistics = (props) => {
  const {
    getUserGameStatistic,
    changePaginationParamsUserGameStatistic,
    user_game_statistic,
    totalPages,
    paginationParams,
    count,
    toggleShowStatisticGames
  } = props;

  const navigate = useNavigate();

  const params = useParams();
  const [sortConfig, setSortConfig] = useState(null);
  const [activeId, setActiveId] = useState();

  useEffect(() => {
    if (sortConfig) {
      getUserGameStatistic(paginationParams, params.id, sortConfig);
    }
  }, [sortConfig]);

  useEffect(() => {
    getUserGameStatistic(paginationParams, params.id);
  }, [paginationParams]);

  const requestSort = (id, key) => {
    setActiveId(id);
    let direction = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({
      key,
      direction
    });
  };

  if (!user_game_statistic) {
    return (
      <div className="loader">
        <LoaderComponent
          size={100}
          thickness={100}
          speed={50}
          style={{
            position: "absolute",
            transform: "translate(50%, 50%)",
            right: "50%",
            bottom: "50%",
            color: "#a90707"
          }}
        />
      </div>
    );
  }

  const onToggle = (el) => {
    const data = {
      isShow: !el.isShow
    };
    toggleShowStatisticGames(el.id, data);
  };

  return (
    <div className={classes.statistic_user_game}>
      <div className={classes.statistic_user_game__table_wrap}>
        <Button
          className={classes.statistic_user_game__button_teams}
          onClick={() => navigate(`${STATISTIC_ROUTE}/scene-teams/${params.id}`)}>
          Статистика по командам
        </Button>
        <Table>
          <thead>
            <tr>
              <td></td>
              {statisticField.map((element) => (
                <td
                  key={element.id}
                  onClick={() => requestSort(element.id, element.sortKey)}
                  className={
                    activeId === element.id
                      ? `${classes[sortConfig.direction]} ${classes.statistic_field}`
                      : `${classes.statistic_field}`
                  }>
                  {element.text}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {user_game_statistic
              ? user_game_statistic.map((el) => {
                  const virtual_objects_type_3d = el.scene.virtual_objects.filter(
                    (el) => el.essence.typeObject === "3D"
                  );
                  return (
                    <tr key={el.id}>
                      <td>
                        <FontAwesomeIcon
                          icon={el.isShow ? faEye : faEyeSlash}
                          color={"#a8b2b5"}
                          size="1x"
                          onClick={() => onToggle(el)}
                          className={`${classes["statistic_user_game__view"]}`}
                        />
                      </td>
                      <td>
                        {el.user.last_name} {el.user.first_name} {el.user.patronymic}
                      </td>
                      <td>{el.user.phone ? el.user.phone : null}</td>
                      <td>{el.user.email}</td>
                      <td>
                        {el.user["collected-objects"] ? el.user["collected-objects"].length : 0}/
                        {virtual_objects_type_3d.length}
                      </td>
                      <td>{formattedDate(el.time, true)}</td>
                      <td>{el.points}</td>
                      <td>
                        {el.user.users_in_teams && el.user.users_in_teams.length
                          ? el.user.users_in_teams[0].team.name_team
                          : "нет команды"}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </div>
      {user_game_statistic ? (
        <Pagination
          countPage={totalPages}
          countElements={count}
          action={changePaginationParamsUserGameStatistic}
          defaultParams={paginationParams}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_game_statistic: state.statistic.user_game.user_game_statistic,
  totalPages: state.statistic.user_game.totalPages,
  paginationParams: state.statistic.user_game.paginationParams,
  count: state.statistic.user_game.count
});

const mapDispatchToProps = {
  getUserGameStatistic,
  changePaginationParamsUserGameStatistic,
  toggleShowStatisticGames
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGameStatistics);
