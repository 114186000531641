import React from "react";
import Checkbox from "../../../../../components/form/checkbox/Checkbox";
import styles from "./styles.module.scss";

const ObjectsItem = (props) => {
  const { item, selectedVirtualObjId, handleCheck } = props;
  return (
    <tr key={item.id}>
      <td>
        <Checkbox
          checked={selectedVirtualObjId === item.id && true}
          name={"virtual-object"}
          type={"checkbox"}
          idElement={item.id}
          onChange={handleCheck}
          classNameCheckmark={styles["objects--checkbox"]}
        />
      </td>
      <td>
        {item.longitude}
        {item.id}
      </td>
      <td>{item.latitude}</td>
      <td>{item.size}</td>
      <td>{item.height}</td>
      <td>{item.points}</td>
      <td>{item.essence ? item.essence.typeObject : null}</td>
      <td>{item.essence ? item.essence.name : null}</td>
    </tr>
  );
};

export default ObjectsItem;
