import { SET_IS_AUTH, LOGOUT, GET_USERS, TOTAL_PAGES_USERS, PAGINATION_PARAMS_USERS, COUNT_USERS } from "./actionType";
import { Resource } from "../../services/ApiResources";
import { Api } from "../../services/api";
import jwtDecode from "jwt-decode";
import { ERROR, SUCCESS } from "../status/actionType";

export const getUsers = (paginationParams) => {
  return async (dispatch) => {
    try {
      const response = await Api.GET(
        {
          url: Resource.USERS
        },
        paginationParams
      );
      if (response) {
        dispatch({
          type: TOTAL_PAGES_USERS,
          payload: response.totalPages
        });
        dispatch({
          type: COUNT_USERS,
          payload: response.users.count
        });
        dispatch({
          type: GET_USERS,
          payload: response.users.rows
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getUser = (id) => {
  return async () => {
    try {
      return await Api.GET({
        id,
        url: Resource.USERS
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const uploadAvatar = (formData) => {
  return async (dispatch) => {
    try {
      const res = await Api.POST(Resource.UPLOAD_AVATAR, formData);
      if (res)
        dispatch({
          type: SUCCESS,
          payload: res.success
        });
      return res;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const changePaginationParams = (paginationParams) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_PARAMS_USERS,
      payload: paginationParams
    });
  };
};

export const loginUser = (login, password) => {
  return async (dispatch) => {
    try {
      const response = await Api.POST(Resource.LOGIN, {
        login,
        password
      });
      if (response.token) {
        const userJwt = jwtDecode(response.token);
        if (userJwt.role === "ADMIN") {
          localStorage.setItem("token", response.token);
          dispatch({
            type: SET_IS_AUTH,
            payload: true
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    dispatch({
      type: LOGOUT
    });
  };
};

export const checkAuth = () => {
  return async (dispatch) => {
    try {
      const tokenStore = localStorage.getItem("token");
      if (tokenStore) {
        const response = await Api.GET({
          url: Resource.AUTH
        });
        if (response.token) {
          const userJwt = jwtDecode(response.token);
          if (userJwt.role === "ADMIN") {
            localStorage.setItem("token", response.token);
            dispatch({
              type: SET_IS_AUTH,
              payload: true
            });
          }
        }
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const editUser = (data) => {
  return async (dispatch) => {
    try {
      const res = await Api.PUT(Resource.USER_EDIT, data);
      if (res)
        dispatch({
          type: SUCCESS,
          payload: res.success
        });
      return res;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    try {
      const res = await Api.DELETE(Resource.USERS, id);
      if (res)
        dispatch({
          type: SUCCESS,
          payload: res.success
        });
      return res;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};
