import React from "react";
import classes from "./DashboardItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

const DashboardItem = (props) => {
  const { data } = props;

  return (
    <div className={classes["dashboard-item"]}>
      <NavLink
        className={({ isActive }) =>
          isActive ? `${classes.active} ${classes["dashboard-item__link"]}` : classes["dashboard-item__link"]
        }
        to={data.pathname}>
        <FontAwesomeIcon icon={data.icon} size="2x" />
        <p className={classes["dashboard-item__title"]}>{data.title}</p>
      </NavLink>
    </div>
  );
};

export default DashboardItem;
