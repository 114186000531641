import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./CircleButton.module.scss";

const CircleButton = (props) => {
  const { path, children, className } = props;

  return (
    <NavLink to={path} className={className ? `${className} ${classes["circle-button"]}` : classes["circle-button"]}>
      {children}
    </NavLink>
  );
};

export default CircleButton;
