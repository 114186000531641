import React, { useEffect } from "react";
import classes from "./Users.module.scss";
import Table from "../../components/layout/table/Table";
import { connect } from "react-redux";
import { getUsers, changePaginationParams, deleteUser } from "../../store/users/action";
import Pagination from "../../components/layout/pagination/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUser, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { DOMAIN } from "../../utils/constants/domain.constans";
import { useNavigate } from "react-router-dom";
import { USERS_ROUTE } from "../../utils/constants/routes.constants";
import LoaderComponent from "../../components/layout/loader/LoaderComponent";

const Users = (props) => {
  const { getUsers, users, totalPages, paginationParams, changePaginationParams, count, deleteUser } = props;
  const navigate = useNavigate();

  useEffect(() => {
    getUsers(paginationParams);
  }, [paginationParams]);

  const editUser = (id) => {
    navigate(`${USERS_ROUTE}/${id}`);
  };

  const removeUser = async (id) => {
    const res = await deleteUser(id);
    if (res) getUsers(paginationParams);
  };

  if (!users) {
    return (
      <div className="loader">
        <LoaderComponent
          size={100}
          thickness={100}
          speed={50}
          style={{
            position: "absolute",
            transform: "translate(50%, 50%)",
            right: "50%",
            bottom: "50%",
            color: "#a90707"
          }}
        />
      </div>
    );
  }

  if (!users.length) {
    return <div className={classes.empty}>Нет пользователей</div>;
  }

  return (
    <div className={classes.users}>
      <div className={classes.users__table_wrap}>
        <Table>
          <thead>
            <tr>
              <td>Аватар</td>
              <td>ФИО</td>
              <td>Почта</td>
              <td>Телефон</td>
              <td>Логин</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {users
              ? users.map((el) => (
                  <tr key={el.id}>
                    <td>
                      <div className={classes.users__wrap_ava}>
                        {el.avatar ? (
                          <img
                            src={`${DOMAIN}/public/avatars/${el.avatar}`}
                            className={classes.users__ava}
                            alt={el.avatar}
                          />
                        ) : (
                          <FontAwesomeIcon icon={faUser} color={"#a8b2b5"} size="2x" />
                        )}
                      </div>
                    </td>
                    <td>
                      {el.last_name} {el.first_name} {el.patronymic}
                    </td>
                    <td>{el.email}</td>
                    <td>{el.phone}</td>
                    <td>{el.login}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faUserEdit}
                        color={"#a8b2b5"}
                        onClick={() => editUser(el.id)}
                        className={`${classes["users__control"]} ${classes["users__control--edit"]}`}
                        size="1x"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        color={"#a8b2b5"}
                        onClick={() => removeUser(el.id)}
                        className={`${classes["users__control"]} ${classes["users__control--remove"]}`}
                        size="1x"
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
      {users ? (
        <Pagination
          countPage={totalPages}
          countElements={count}
          action={changePaginationParams}
          defaultParams={paginationParams}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.users,
  totalPages: state.users.totalPages,
  paginationParams: state.users.paginationParams,
  count: state.users.count
});

const mapDispatchToProps = {
  getUsers,
  changePaginationParams,
  deleteUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
