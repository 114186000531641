import React from "react";
import { connect } from "react-redux";
import EssencesSample from "../components/EssencesSample";
import { createEssence } from "../../../store/essences/action";

const EssencesCreate = (props) => {
  const { createEssence } = props;

  return <EssencesSample createEssence={createEssence} />;
};

const mapDispatchToProps = {
  createEssence
};

export default connect(null, mapDispatchToProps)(EssencesCreate);
