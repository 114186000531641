import { Api } from "../../../services/api";
import { Resource } from "../../../services/ApiResources";
import {
  GET_STATISTIC_GAME,
  TOTAL_PAGES_STATISTIC_GAME,
  PAGINATION_PARAMS_STATISTIC_GAME,
  COUNT_STATISTIC_GAME
} from "./actionType";

export const getStatisticGames = (paginationParams) => {
  return async (dispatch) => {
    try {
      const response = await Api.GET(
        {
          url: Resource.STATISTIC_GAMES
        },
        paginationParams
      );
      if (response) {
        dispatch({
          type: TOTAL_PAGES_STATISTIC_GAME,
          payload: response.totalPages
        });
        dispatch({
          type: COUNT_STATISTIC_GAME,
          payload: response.statistic_game.count
        });
        dispatch({
          type: GET_STATISTIC_GAME,
          payload: response.statistic_game.rows
        });
      }
    } catch (e) {
      dispatch({
        type: TOTAL_PAGES_STATISTIC_GAME,
        payload: null
      });
      dispatch({
        type: COUNT_STATISTIC_GAME,
        payload: null
      });
      dispatch({
        type: GET_STATISTIC_GAME,
        payload: null
      });
      console.log(e);
    }
  };
};

export const changePaginationParamsStatisticGames = (paginationParams) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_PARAMS_STATISTIC_GAME,
      payload: paginationParams
    });
  };
};
