import React, { useEffect } from "react";
import classes from "./Statistic.module.scss";
import { connect } from "react-redux";
import Table from "../../components/layout/table/Table";
import Pagination from "../../components/layout/pagination/Pagination";
import { changePaginationParamsStatisticGames, getStatisticGames } from "../../store/statistic/game-statistic/action";
import LoaderComponent from "../../components/layout/loader/LoaderComponent";
import { formattedDate } from "../../helpers/dateHelper";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATISTIC_ROUTE } from "../../utils/constants/routes.constants";
import { useNavigate } from "react-router-dom";

const Statistic = (props) => {
  const {
    getStatisticGames,
    changePaginationParamsStatisticGames,
    statistic_game,
    totalPages,
    paginationParams,
    count
  } = props;
  const navigate = useNavigate();

  useEffect(() => {
    getStatisticGames(paginationParams);
  }, [paginationParams]);

  const view = (el) => {
    navigate(`${STATISTIC_ROUTE}/user-game/${el.id}`);
  };

  if (!statistic_game) {
    return (
      <div className="loader">
        <LoaderComponent
          size={100}
          thickness={100}
          speed={50}
          style={{
            position: "absolute",
            transform: "translate(50%, 50%)",
            right: "50%",
            bottom: "50%",
            color: "#a90707"
          }}
        />
      </div>
    );
  }

  return (
    <div className={classes.statistic}>
      <div className={classes.statistic__table_wrap}>
        <Table>
          <thead>
            <tr>
              <td>Название</td>
              <td>Колл-во участников</td>
              <td>Дата</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {statistic_game
              ? statistic_game.map((el) => (
                  <tr key={el.id}>
                    <td>{el.name_game}</td>
                    <td>{el["user-game-statistics"].length}</td>
                    <td>{formattedDate(el["user-game-statistics"][0].createdAt)}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faEye}
                        color={"#a8b2b5"}
                        size="1x"
                        onClick={() => view(el)}
                        className={`${classes["statistic__control"]}`}
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
      {statistic_game ? (
        <Pagination
          countPage={totalPages}
          countElements={count}
          action={changePaginationParamsStatisticGames}
          defaultParams={paginationParams}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  statistic_game: state.statistic.game.statistic_game,
  totalPages: state.statistic.game.totalPages,
  paginationParams: state.statistic.game.paginationParams,
  count: state.statistic.game.count
});

const mapDispatchToProps = {
  getStatisticGames,
  changePaginationParamsStatisticGames
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistic);
