import React, { useEffect, useState } from "react";
import AppRouter from "./AppRouter";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkAuth } from "../../store/users/action";
import Dashboard from "../layout/dashboard/Dashboard";
import Modal from "../layout/modal/Modal";
import LoaderComponent from "../layout/loader/LoaderComponent";
import Expire from "../layout/expire/Expire";
import ModalStatus from "../layout/modal-status/ModalStatus";

const Container = (props) => {
  const { isAuth, checkAuth, error, success } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await checkAuth();
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <div className="loader">
        <LoaderComponent
          size={100}
          thickness={100}
          speed={50}
          style={{
            position: "absolute",
            transform: "translate(50%, 50%)",
            right: "50%",
            bottom: "50%",
            color: "#a90707"
          }}
        />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <div className="container">
        <Modal />
        {isAuth && <Dashboard />}
        <div className={isAuth ? "pages" : "pages-auth"}>
          {error && (
            <div className="modal-status__wrap">
              {error.map((err, i) => {
                return (
                  err && (
                    <Expire className="modal-status__expire" key={i} delay={5000}>
                      <ModalStatus error={err} />
                    </Expire>
                  )
                );
              })}
            </div>
          )}
          {success && (
            <div className="modal-status__wrap">
              {success.map((item, i) => {
                return (
                  item && (
                    <Expire className="modal-status__expire" key={i} delay={5000}>
                      <ModalStatus success={item} />
                    </Expire>
                  )
                );
              })}
            </div>
          )}
          <AppRouter />
        </div>
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.users.isAuth,
  error: state.status.error,
  success: state.status.success
});

const mapDispatchToProps = {
  checkAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
