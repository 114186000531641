import React, { useEffect, useRef, useState } from "react";
import classes from "./ScenesEdit.module.scss";
import ScenesObject from "../components/scenes-object/ScenesObject";
import VirtualObjects from "../components/virtual-objects/VirtualObjects";
import { connect } from "react-redux";
import { createScene, editScene } from "../../../store/scenes/action";
import { useParams } from "react-router-dom";
import Map from "../components/map/Map";
import {
  getVirtualObject,
  createVirtualObject,
  deleteVirtualObject,
  clearVirtualObject,
  editVirtualObject,
  toggleConsistent,
  createBeaconChildren,
  editBeaconChildren,
  dragAndDropBeaconChildren,
  deleteBeaconChildren
} from "../../../store/virtual-object/action";
import { essenceTagHintsGet, getEssences } from "../../../store/essences/action";

const initialDataVirtualObject = {
  height: 0,
  latitude: "",
  longitude: "",
  mark_color: "#00aeff",
  points: 0,
  size: 0
};

const initialDataBeacon = {
  height: 0,
  latitude: "",
  longitude: "",
  mark_color: "#00aeff",
  size: 0
};

const ScenesEdit = (props) => {
  const {
    virtual_objects,
    essenceTagHints,
    essences,
    createScene,
    editScene,
    getVirtualObject,
    createVirtualObject,
    createBeaconChildren,
    deleteVirtualObject,
    clearVirtualObject,
    editVirtualObject,
    editBeaconChildren,
    getEssences,
    toggleConsistent,
    dragAndDropBeaconChildren,
    deleteBeaconChildren,
    essenceTagHintsGet
  } = props;

  const params = useParams();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const [sceneId, setSceneId] = useState("");

  const [typeObject, setTypeObject] = useState("3D");
  const [essenceOptions, setEssenceOptions] = useState([]);
  const [essenceSelected, setEssenceSelected] = useState(null);
  const [essenceBeaconSelected, setEssenceBeaconSelected] = useState(null);
  const [errorEssence, setErrorEssence] = useState("");
  const [errorEssenceBeacon, setErrorEssenceBeacon] = useState("");
  const [beaconChildren, setBeaconChildren] = useState([]);
  const [selectedVirtualObjId, setSelectedVirtualObjId] = useState(null);
  const [selectedBeaconChildrenId, setSelectedBeaconChildrenId] = useState(null);
  const [virtualObject, setVirtualObject] = useState(initialDataVirtualObject);
  const [beacon, setBeacon] = useState(initialDataBeacon);
  const [isConsistent, setIsConsistent] = useState(false);
  const [sortTag, setSortTag] = useState("");

  useEffect(() => {
    setSceneId(params.id);
  }, []);

  useEffect(() => {
    if (params.id) getVirtualObject(params.id, { sortTag });
  }, [params.id, sortTag]);

  useEffect(() => {
    (async () => {
      try {
        await getEssences({
          page: 0,
          pageSize: 9999999
        });
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (virtual_objects) {
      const virtual_object = virtual_objects.find((el) => el.id === selectedVirtualObjId);
      if (virtual_object) {
        setIsConsistent(virtual_object.isConsistent);
        setTypeObject(virtual_object.essence.typeObject);
        setEssenceSelected(virtual_object.essence.id);
        setVirtualObject(virtual_object);
        setBeaconChildren(virtual_object.beacon_children);
      }
    }
  }, [virtual_objects, selectedVirtualObjId]);

  useEffect(() => {
    if (virtual_objects && beaconChildren) {
      const beacon = beaconChildren.find((el) => el.id === selectedBeaconChildrenId);
      if (beacon) {
        setEssenceBeaconSelected(beacon.essence.id);
        setBeacon(beacon);
      }
    }
  }, [virtual_objects, selectedBeaconChildrenId]);

  useEffect(() => {
    if (essences) {
      const newEssences = essences.map((el) => {
        return {
          value: el.id,
          label: el.name,
          type: el.typeObject
        };
      });

      setEssenceOptions(newEssences);
    }
  }, [essences]);

  const formDefault = () => {
    setTypeObject("3D");
    setEssenceOptions([]);
    setEssenceSelected(null);
    setErrorEssence("");
    setBeaconChildren([]);
    setSelectedVirtualObjId(null);
    setSelectedBeaconChildrenId(null);
    setVirtualObject(initialDataVirtualObject);
    setIsConsistent(false);
  };

  const onDuplicateObject = async () => {
    const result = virtual_objects.filter((el) => el.id === +selectedVirtualObjId);
    if (!result.length) return;

    const data = {
      longitude: result[0].longitude,
      latitude: result[0].latitude,
      size: result[0].size,
      height: result[0].height,
      points: result[0].points,
      sceneId: result[0].sceneId,
      mark_color: result[0].mark_color,
      essenceId: result[0].essence.id
    };

    await createVirtualObject(data);
  };

  const onDeleteObject = async () => {
    if (!selectedVirtualObjId) return;
    const res = await deleteVirtualObject(selectedVirtualObjId);
    if (res) {
      setSelectedVirtualObjId(virtual_objects[1].id);
    }
  };

  const onEditObject = async () => {
    if (!selectedVirtualObjId) return;
    if (!essenceSelected) {
      setErrorEssence("Вы не выбрали объект");
      setTimeout(() => {
        setErrorEssence("");
      }, 5000);
      return;
    }
    setErrorEssence("");
    const data = {
      ...virtualObject,
      sceneId: params.id,
      essenceId: essenceSelected
    };

    await editVirtualObject(data, +selectedVirtualObjId);
  };

  const onEditBeacon = async () => {
    if (!selectedBeaconChildrenId) return;
    if (!essenceBeaconSelected) {
      setErrorEssenceBeacon("Вы не выбрали объект");
      setTimeout(() => {
        setErrorEssenceBeacon("");
      }, 5000);
      return;
    }
    setErrorEssenceBeacon("");
    const data = {
      ...beacon,
      sceneId: params.id,
      essenceId: essenceBeaconSelected,
      virtualObjectId: selectedVirtualObjId
    };

    await editBeaconChildren(data, +selectedBeaconChildrenId);
  };

  const onClear = async () => {
    await clearVirtualObject(params.id);
    formDefault();
  };

  const onEssenceSelected = (value) => {
    setEssenceSelected(value);
    setVirtualObject((data) => ({ ...data, points: 0 }));
  };

  const onChangeConsistent = (isChecked) => {
    setIsConsistent(isChecked);
    toggleConsistent({ id: selectedVirtualObjId, isConsistent: isChecked });
  };

  const onCreateMarker = async (lng, lat) => {
    let data = {
      ...virtualObject,
      longitude: lng,
      latitude: lat,
      sceneId: params.id,
      essenceId: essenceSelected
    };

    if (!data.essenceId) {
      setErrorEssence("Вы не выбрали объект");
      setTimeout(() => {
        setErrorEssence("");
      }, 5000);
      return;
    }

    if (isConsistent && typeObject === "BEACON") {
      if (!essenceBeaconSelected) {
        setErrorEssenceBeacon("Вы не выбрали объект");
        setTimeout(() => {
          setErrorEssenceBeacon("");
        }, 5000);
        return;
      }
      data = {
        ...beacon,
        longitude: lng,
        latitude: lat,
        sceneId: params.id,
        essenceId: essenceSelected,
        virtualObjectId: selectedVirtualObjId
      };
      const response = await createBeaconChildren(data);
      setSelectedBeaconChildrenId(response.id);
      return;
    }

    const response = await createVirtualObject(data);
    setSelectedVirtualObjId(response.id);
  };

  const eventHandlersVirtualObject = async (e, isClick) => {
    let virtual_object = virtual_objects.reduce((acc, item) => {
      if (item.id === e.options.data.id) {
        acc = item;
      }
      return acc;
    }, {});

    virtual_object.longitude = e.getLatLng().lng;
    virtual_object.latitude = e.getLatLng().lat;

    if (!isClick) await editVirtualObject(virtual_object, virtual_object.id);
    setSelectedVirtualObjId(virtual_object.id);
  };

  const eventHandlersBeaconChildren = async (e, isClick) => {
    let beacon_children = beaconChildren.reduce((acc, item) => {
      if (item.id === e.options.data.id) {
        acc = item;
      }
      return acc;
    }, {});

    beacon_children.longitude = e.getLatLng().lng;
    beacon_children.latitude = e.getLatLng().lat;

    if (!isClick) await editBeaconChildren(beacon_children, beacon_children.id);
    setSelectedBeaconChildrenId(beacon_children.id);
  };

  const onDragEnd = () => {
    dragAndDropBeaconChildren({ dragItem: dragItem.current, dragOverItem: dragOverItem.current }, selectedVirtualObjId);
  };

  const removeBeacon = () => {
    deleteBeaconChildren(selectedVirtualObjId, selectedBeaconChildrenId);
  };

  return (
    <div className={classes["scenes-edit"]}>
      <div className={classes["scenes-edit__wrapper"]}>
        <h2>Редактирование сцены</h2>
        <ScenesObject request={editScene} setSceneId={setSceneId} sceneId={sceneId} createScene={createScene} />
        <h2>Карта</h2>
        <Map
          isConsistent={isConsistent}
          virtualObjects={virtual_objects}
          beaconChildren={beaconChildren}
          selectedVirtualObjId={selectedVirtualObjId}
          selectedBeaconChildrenId={selectedBeaconChildrenId}
          onCreateMarker={onCreateMarker}
          eventHandlersVirtualObject={eventHandlersVirtualObject}
          eventHandlersBeaconChildren={eventHandlersBeaconChildren}
        />
      </div>
      <div className={classes["scenes-edit__wrapper"]}>
        <h2>Редактирование точки</h2>
        <VirtualObjects
          essenceTagHints={essenceTagHints}
          beacon={beacon}
          dragItem={dragItem}
          dragOverItem={dragOverItem}
          virtualObject={virtualObject}
          beaconChildren={beaconChildren}
          virtualObjects={virtual_objects}
          typeObject={typeObject}
          essenceOptions={essenceOptions}
          essenceSelected={essenceSelected}
          errorEssence={errorEssence}
          errorEssenceBeacon={errorEssenceBeacon}
          isConsistent={isConsistent}
          selectedVirtualObjId={selectedVirtualObjId}
          essenceBeaconSelected={essenceBeaconSelected}
          setSelectedVirtualObjId={setSelectedVirtualObjId}
          selectedBeaconChildrenId={selectedBeaconChildrenId}
          setSelectedBeaconChildrenId={setSelectedBeaconChildrenId}
          onDuplicateObject={onDuplicateObject}
          onDeleteObject={onDeleteObject}
          onClear={onClear}
          onEditObject={onEditObject}
          setTypeObject={setTypeObject}
          setEssenceOptions={setEssenceOptions}
          setEssenceSelected={setEssenceSelected}
          onEssenceSelected={onEssenceSelected}
          setVirtualObject={setVirtualObject}
          setIsConsistent={setIsConsistent}
          onChangeConsistent={onChangeConsistent}
          onDragEnd={onDragEnd}
          setBeacon={setBeacon}
          setEssenceBeaconSelected={setEssenceBeaconSelected}
          onEditBeacon={onEditBeacon}
          removeBeacon={removeBeacon}
          essenceTagHintsGet={essenceTagHintsGet}
          setSortTag={setSortTag}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  virtual_objects: state.virtualObject.virtual_objects,
  essences: state.essences.data,
  essenceTagHints: state.essences.essenceTagHints
});

const mapDispatchToProps = {
  createScene,
  editScene,
  getVirtualObject,
  createVirtualObject,
  createBeaconChildren,
  deleteVirtualObject,
  clearVirtualObject,
  editVirtualObject,
  editBeaconChildren,
  getEssences,
  toggleConsistent,
  dragAndDropBeaconChildren,
  deleteBeaconChildren,
  essenceTagHintsGet
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenesEdit);
