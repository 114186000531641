import React from "react";
import styles from "./styles.module.scss";

const TagItem = (props) => {
  const { item, onRemove } = props;
  return (
    <div className={styles.tagItem}>
      <span>{item.name}</span>
      <span className={styles.tagRemove} onClick={() => onRemove(item.id)}></span>
    </div>
  );
};

export default TagItem;
