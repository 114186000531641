import { combineReducers } from "redux";
import usersReducer from "./users/usersReducer";
import scenesReducer from "./scenes/scenesReducer";
import modalReducer from "./modal/modalReducer";
import virtualObjectReducer from "./virtual-object/virtualObjectReducer";
import statusReducer from "./status/statusReducer";
import bannersReducer from "./banners/bannersReducer";
import { statisticReducer } from "./statistic/statisticReducer";
import essencesReducer from "./essences/essencesReducer";
import mapReducer from "./map/mapReducer";
import teamsReducer from "./teams/teamsReducer";

export const RootReducer = combineReducers({
  users: usersReducer,
  scenes: scenesReducer,
  modal: modalReducer,
  virtualObject: virtualObjectReducer,
  status: statusReducer,
  statistic: statisticReducer,
  banners: bannersReducer,
  essences: essencesReducer,
  map: mapReducer,
  teams: teamsReducer
});
