import React from "react";
import { connect } from "react-redux";
import BeaconChildren from "./beacon-children/BeaconChildren";
import Objects from "./objects/Objects";
import VirtualObjectForm from "./virtual-object-form/VirtualObjectForm";
import BeaconChildrenForm from "./beacon-children-form/BeaconChildrenForm";

const VirtualObjects = (props) => {
  const {
    essenceTagHints,
    beacon,
    dragItem,
    dragOverItem,
    virtualObject,
    beaconChildren,
    virtualObjects,
    typeObject,
    essenceOptions,
    essenceSelected,
    errorEssence,
    errorEssenceBeacon,
    isConsistent,
    selectedVirtualObjId,
    essenceBeaconSelected,
    selectedBeaconChildrenId,
    setSelectedVirtualObjId,
    setSelectedBeaconChildrenId,
    onDuplicateObject,
    onDeleteObject,
    onClear,
    onEditObject,
    setTypeObject,
    setEssenceSelected,
    onEssenceSelected,
    setVirtualObject,
    setIsConsistent,
    onChangeConsistent,
    onDragEnd,
    setBeacon,
    setEssenceBeaconSelected,
    onEditBeacon,
    removeBeacon,
    essenceTagHintsGet,
    setSortTag
  } = props;

  return (
    <div>
      <VirtualObjectForm
        essenceTagHints={essenceTagHints}
        selectedVirtualObjId={selectedVirtualObjId}
        virtualObject={virtualObject}
        typeObject={typeObject}
        essenceOptions={essenceOptions}
        essenceSelected={essenceSelected}
        errorEssence={errorEssence}
        isConsistent={isConsistent}
        onDuplicate={onDuplicateObject}
        onDelete={onDeleteObject}
        onClear={onClear}
        onEdit={onEditObject}
        setTypeObject={setTypeObject}
        setEssenceSelected={setEssenceSelected}
        onEssenceSelected={onEssenceSelected}
        setVirtualObject={setVirtualObject}
        setIsConsistent={setIsConsistent}
        onChangeConsistent={onChangeConsistent}
        essenceTagHintsGet={essenceTagHintsGet}
        setSortTag={setSortTag}
      />
      <Objects
        virtualObjects={virtualObjects}
        selectedVirtualObjId={selectedVirtualObjId}
        setSelectedVirtualObjId={setSelectedVirtualObjId}
      />
      {isConsistent ? (
        <BeaconChildrenForm
          beacon={beacon}
          essenceBeaconSelected={essenceBeaconSelected}
          essenceOptions={essenceOptions}
          errorEssenceBeacon={errorEssenceBeacon}
          setBeacon={setBeacon}
          setEssenceBeaconSelected={setEssenceBeaconSelected}
          onEditBeacon={onEditBeacon}
          removeBeacon={removeBeacon}
        />
      ) : null}
      {isConsistent ? (
        <BeaconChildren
          dragItem={dragItem}
          dragOverItem={dragOverItem}
          beaconChildren={beaconChildren}
          selectedBeaconChildrenId={selectedBeaconChildrenId}
          setSelectedBeaconChildrenId={setSelectedBeaconChildrenId}
          onDragEnd={onDragEnd}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  virtual_objects: state.virtualObject.virtual_objects
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualObjects);
