import React, { useEffect, useState } from "react";
import classes from "./TeamItem.module.scss";
import Input from "../../../../components/form/input/Input";
import { SketchPicker } from "react-color";
import Button from "../../../../components/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { removeTeam } from "../../../../store/teams/action";

const TeamItem = (props) => {
  const { team, removeTeam, onCreateTeam, onEditTeam, onTouchTeamEdit, isTeam } = props;
  const { id, name_team, color } = team;

  const [teamColor, setTeamColor] = useState("#44750D");
  const [nameTeam, setNameTeam] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (color) setTeamColor(color);
    if (name_team) setNameTeam(name_team);
  }, [name_team, color]);

  const handleChange = (color) => {
    setTeamColor(color.hex);
  };

  const handleClick = () => {
    if (!team.id || isEdit) setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleCreateTeam = () => {
    onCreateTeam({
      name_team: nameTeam,
      color: teamColor,
      isTeamCreated: true
    });
  };

  const handleEditTeam = () => {
    onEditTeam({
      id: team.id,
      name_team: nameTeam,
      color: teamColor,
      isTeamCreated: true
    });
    setIsEdit(false);
  };

  const handleClickEdit = () => {
    onTouchTeamEdit({
      isTeamCreated: false
    });
    setIsEdit(true);
  };

  return (
    <div className={classes["team-item"]}>
      <div className={classes["team-item__swatch"]} onClick={handleClick}>
        <div
          className={classes["team-item__color"]}
          style={{
            background: teamColor
          }}
        />
      </div>
      {displayColorPicker ? (
        <div className={classes["team-item__popover"]}>
          <div className={classes["team-item__cover"]} onClick={handleClose} />
          <SketchPicker color={teamColor} onChange={handleChange} />
        </div>
      ) : null}
      {!team.id || isEdit ? (
        <Input
          type={"text"}
          name={"team-name"}
          value={nameTeam}
          placeholder={"Введите название команды"}
          classNameWrap={classes["team-item__input-wrap"]}
          classNameInput={classes["team-item__input"]}
          onChange={(e) => setNameTeam(e.target.value)}
        />
      ) : (
        <div className={classes["team-item__text-disabled"]}>{nameTeam}</div>
      )}
      {!team.id ? (
        <Button
          disabled={!nameTeam.length || !isTeam}
          className={classes["team-item__button"]}
          onClick={() => handleCreateTeam()}>
          <FontAwesomeIcon icon={faCheck} color={"#ffffff"} size="1x" />
        </Button>
      ) : (
        <>
          {!isEdit ? (
            <Button
              disabled={!nameTeam.length || !isTeam}
              className={`${classes["team-item__button"]} ${classes["team-item__button--edit"]}`}
              onClick={() => handleClickEdit()}>
              <FontAwesomeIcon icon={faEdit} color={"#ffffff"} size="1x" />
            </Button>
          ) : (
            <Button
              disabled={!nameTeam.length || !isTeam}
              className={`${classes["team-item__button"]} ${classes["team-item__button--edit"]}`}
              onClick={() => handleEditTeam()}>
              <FontAwesomeIcon icon={faCheck} color={"#ffffff"} size="1x" />
            </Button>
          )}
          <Button
            className={`${classes["team-item__button"]} ${classes["team-item__button--delete"]}`}
            onClick={() => removeTeam(id)}>
            <FontAwesomeIcon icon={faTrash} color={"#ffffff"} size="1x" />
          </Button>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  removeTeam
};

export default connect(null, mapDispatchToProps)(TeamItem);
