import React from "react";
import classes from "./Modal.module.scss";
import { connect } from "react-redux";
import { hideModal } from "../../../store/modal/action";

const Modal = (props) => {
  const { modal, hideModal } = props;

  if (!modal) {
    return null;
  }

  return (
    <div className={classes["modal-overlay"]}>
      <span className={classes["modal-close"]} onClick={hideModal}>
        &#10005;
      </span>
      <div className={modal.className ? `${modal.className} ${classes.modal}` : classes.modal}>
        {modal.title ? <div className={classes["modal-title"]}>{modal.title}</div> : null}
        {modal.childrenComponent ? modal.childrenComponent : null}
        <div
          dangerouslySetInnerHTML={{
            __html: modal.children
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modal.modal
});

const mapDispatchToProps = {
  hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
