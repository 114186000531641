import React, { useState } from "react";
import classes from "./ScenesCreate.module.scss";
import { connect } from "react-redux";
import { createScene } from "../../../store/scenes/action";
import ScenesObject from "../components/scenes-object/ScenesObject";

const ScenesCreate = (props) => {
  const { createScene } = props;

  const [sceneId, setSceneId] = useState("");

  return (
    <div className={classes["scenes-components"]}>
      <div className={classes["scenes-create__wrapper"]}>
        <h2>Создание сцены</h2>
        <ScenesObject setSceneId={setSceneId} sceneId={sceneId} request={createScene} />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  createScene
};

export default connect(null, mapDispatchToProps)(ScenesCreate);
