import {
  GET_STATISTIC_GAME,
  TOTAL_PAGES_STATISTIC_GAME,
  PAGINATION_PARAMS_STATISTIC_GAME,
  COUNT_STATISTIC_GAME
} from "./actionType";

const initialState = {
  statistic_game: null,
  paginationParams: {
    page: 0,
    pageSize: 10
  },
  totalPages: null,
  count: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STATISTIC_GAME:
      return {
        ...state,
        statistic_game: payload
      };
    case TOTAL_PAGES_STATISTIC_GAME:
      return {
        ...state,
        totalPages: payload
      };
    case COUNT_STATISTIC_GAME:
      return {
        ...state,
        count: payload
      };
    case PAGINATION_PARAMS_STATISTIC_GAME:
      return {
        ...state,
        paginationParams: payload
      };
    default:
      return {
        ...state
      };
  }
};
