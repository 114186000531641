import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { authRoutes, publicRoutes } from "../../routes";
import { SCENES_ROUTE, LOGIN_ROUTE } from "../../utils/constants/routes.constants";
import { connect } from "react-redux";

const AppRouter = (props) => {
  const { isAuth } = props;

  return (
    <Routes>
      {isAuth && authRoutes.map(({ path, Component }) => <Route key={path} path={path} element={Component} exact />)}
      {!isAuth && publicRoutes.map(({ path, Component }) => <Route key={path} path={path} element={Component} exact />)}
      {isAuth && <Route path="*" element={<Navigate to={SCENES_ROUTE} />} exact />}
      {!isAuth && <Route path="*" element={<Navigate to={LOGIN_ROUTE} />} exact />}
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.users.isAuth
});

export default connect(mapStateToProps, null)(AppRouter);
