import React, { useEffect, useState } from "react";
import classes from "./Pagination.module.scss";

const multilanguage = {
  ru: {
    pageSizeAll: "все",
    totalPages: "всего страниц:",
    totalElements: "всего элементов:"
  },
  en: {
    pageSizeAll: "all",
    totalPages: "total pages:",
    totalElements: "total elements:"
  }
};

const Pagination = (props) => {
  const { countPage, countElements, defaultParams, action, lang = "ru" } = props;

  const pageSizeContent = [
    5,
    10,
    20,
    50,
    100,
    {
      pageSize: 9999999,
      name: multilanguage[lang].pageSizeAll
    }
  ];

  const [page, setPage] = useState(defaultParams.page);
  const [pageSize, setPageSize] = useState(defaultParams.pageSize);

  useEffect(() => {
    action({
      pageSize,
      page
    });
  }, [pageSize, page]);

  const onClickPageSize = (el) => {
    if (el === 9999999) {
      setPageSize(9999999);
      setPage(0);
      return;
    }
    if (countPage / el < 1) setPage(0);
    if (countPage / el >= 1) setPage(Math.round(countPage / el) - 1);
    setPageSize(el);
  };

  const onClickPage = (el) => {
    setPage(el);
  };

  const nextPage = () => {
    if (page + 1 < countPage) setPage(page + 1);
  };

  const prevPage = () => {
    if (page > 0) setPage(page - 1);
  };

  const renderPages = () => {
    let arrPage = [];
    for (let j = 1; countPage >= j; j++) {
      arrPage.push(j);
    }

    if (countPage > 3 && countPage - page > 2) arrPage.splice(page + 2, countPage - page - 3, "...");
    if (page > 1 && countPage - page > 2) arrPage.splice(0, page - 1);
    if (countPage - page <= 2) arrPage.splice(0, page - 1, "...");

    if (countPage <= 3) {
      arrPage = [];
      for (let j = 1; countPage >= j; j++) {
        arrPage.push(j);
      }
    }

    return (
      <div className={classes.pagination__pages}>
        {countPage > 1 ? <span onClick={prevPage} className={classes.prevArrow}></span> : null}
        {arrPage.map((el) => {
          if (page + 1 === el)
            return (
              <span className={classes["active"]} key={el}>
                {el}
              </span>
            );
          return (
            <span key={el} onClick={() => onClickPage(el - 1)}>
              {el}
            </span>
          );
        })}
        {countPage > 1 ? <span onClick={nextPage} className={classes.nextArrow}></span> : null}
      </div>
    );
  };

  const renderPageSize = () => {
    return (
      <div className={classes["pagination__page-size"]}>
        <p>
          Показывать:{" "}
          {pageSizeContent.map((el) => {
            if (el === pageSize)
              return (
                <span className={classes["active"]} key={el}>
                  {el}
                </span>
              );
            if (el.pageSize === pageSize)
              return (
                <span className={classes["active"]} key={el.pageSize}>
                  {el.name}
                </span>
              );
            if (el.pageSize === 9999999)
              return (
                <span onClick={() => onClickPageSize(el.pageSize)} key={el.pageSize}>
                  {el.name}
                </span>
              );
            return (
              <span data-value={el} onClick={() => onClickPageSize(el)} key={el}>
                {el}
              </span>
            );
          })}
        </p>
      </div>
    );
  };

  return (
    <div className={classes.pagination}>
      <div className={classes.pagination__info}>
        <p>
          {multilanguage[lang].totalPages}
          &nbsp;
          <span>{countPage}</span>
        </p>
        {countElements ? (
          <p>
            {multilanguage[lang].totalElements}
            &nbsp;
            <span>{countElements}</span>
          </p>
        ) : null}
      </div>
      {renderPages()}
      {renderPageSize()}
    </div>
  );
};

export default Pagination;
