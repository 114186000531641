import React, { useEffect } from "react";
import Leaflet from "leaflet";
import { Popup, Marker, useMap } from "react-leaflet";

const CustomMarker = (props) => {
  const { eventHandlers, element, renderIconMarker, selectedMarker, color } = props;
  const map = useMap();

  useEffect(() => {
    if (selectedMarker && element.id === selectedMarker) {
      map.setView([element.latitude, element.longitude], 20);
    }
  }, [selectedMarker]);

  return (
    <Marker
      draggable={true}
      eventHandlers={{
        dblclick: (e) => {
          map.setView([e.latlng.lat, e.latlng.lng], 20);
        },
        dragend: async (e) => {
          await eventHandlers(e.target);
        },
        click: (e) => {
          setTimeout(async () => {
            await eventHandlers(e.target, true);
            map.setView([e.latlng.lat, e.latlng.lng], 20);
          }, 20);
        }
      }}
      position={{
        lat: element.latitude,
        lng: element.longitude
      }}
      data={element}
      icon={Leaflet.divIcon({
        html: renderIconMarker(selectedMarker && selectedMarker === element.id ? color : element.mark_color)
      })}>
      <Popup>{element.essence ? element.essence.typeObject : null}</Popup>
    </Marker>
  );
};

export default CustomMarker;
