export const ALTITUDE_MODELS = [
  {
    id: 1,
    value: "Absolute",
    label: "Absolute"
  },
  {
    id: 2,
    value: "DeviceRelative",
    label: "DeviceRelative"
  },
  {
    id: 3,
    value: "GroundRelative",
    label: "GroundRelative"
  },
  {
    id: 4,
    value: "Ignore",
    label: "Ignore"
  }
];
