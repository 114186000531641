import React from "react";
import classes from "./ModalStatus.module.scss";

const ModalStatus = (props) => {
  const { error, success } = props;
  return (
    <div className={success ? `${classes["modal-status"]} ${classes.success}` : classes["modal-status"]}>
      {error && (
        <div>
          <p className={classes["modal-status__title"]}>Ошибка</p>
          <p>{error}</p>
        </div>
      )}
      {success && (
        <div>
          <p className={classes["modal-status__title"]}>Успешно {success.action}</p>
          <p className={classes.text}>{success}</p>
        </div>
      )}
    </div>
  );
};

export default ModalStatus;
