import React, { useState, useEffect } from "react";
import classes from "./EssencesSample.module.scss";
import Button from "../../../components/button/Button";
import Input from "../../../components/form/input/Input";
import Select from "react-select";
import { ESSENCES_ROUTE } from "../../../utils/constants/routes.constants";
import { useNavigate } from "react-router-dom";
import { TYPE_OBJECTS } from "../../../utils/constants/objects.constants";
import ColorBox from "../../../components/color-box/ColorBox";
import Tags from "../../../components/hashtags/Tags";

const customStyles = {
  container: (provided) => ({
    ...provided,
    zIndex: 5000
  }),
  control: (provided) => ({
    ...provided,
    "borderRadius": "0",
    "borderColor": "#000",
    "boxShadow": "none",
    "&:hover": {
      borderColor: "none"
    }
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "300px"
  })
};

const EssencesSample = (props) => {
  const {
    id,
    essenceCurrent,
    essenceTagHints,
    clearEssence,
    getCurrentEssence,
    editEssences,
    createEssence,
    createTag,
    removeTag,
    essenceTagHintsGet
  } = props;

  const navigate = useNavigate();

  const [url, setUrl] = useState("");
  const [urlOnView, setUrlOnView] = useState("");
  const [textOnView, setTextOnView] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [amountAttempts, setAmountAttempts] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [wrongAnswer, setWrongAnswer] = useState("");
  const [nameObject, setNameObject] = useState("");
  const [typeObject, setTypeObject] = useState("3D");
  const [price, setPrice] = useState(0);
  const [prize, setPrize] = useState(0);
  const [tags, setTags] = useState([]);
  const [errorNameObj, setErrorNameObj] = useState("");
  const [errorPrice, setErrorPrice] = useState("");
  const [color, setColor] = useState("#B8E986");
  const [hint, setHint] = useState(null);
  const [costPrompting, setCostPrompting] = useState(null);

  useEffect(() => {
    if (id) {
      getCurrentEssence(id);
    }
  }, [id]);

  useEffect(() => {
    if (essenceCurrent) {
      setTags(essenceCurrent.tags);
      setUrl(essenceCurrent.url || "");
      setNameObject(essenceCurrent.name || "");
      setUrlOnView(essenceCurrent.urlOnView || "");
      setTextOnView(essenceCurrent.textOnView || "");
      setTypeObject(essenceCurrent.typeObject || "3D");
      setColor(essenceCurrent.color || "#B8E986");
      if (essenceCurrent.essence_paid_bonus) {
        setPrice(essenceCurrent.essence_paid_bonus.price || 0);
        setPrize(essenceCurrent.essence_paid_bonus.prize || 0);
      }
      if (essenceCurrent.essence_question) {
        setPrize(essenceCurrent.essence_question.prize || 0);
        setQuestion(essenceCurrent.essence_question.question || "");
        setAnswer(essenceCurrent.essence_question.answer || "");
        setAmountAttempts(essenceCurrent.essence_question.amountAttempts || 0);
        setCorrectAnswer(essenceCurrent.essence_question.correctAnswer || "");
        setWrongAnswer(essenceCurrent.essence_question.wrongAnswer || "");
        setHint(essenceCurrent.essence_question.hint || "");
        setCostPrompting(essenceCurrent.essence_question.costPrompting || 0);
      }
    }
  }, [essenceCurrent]);

  const onCreate = async () => {
    setErrorPrice("");
    setErrorNameObj("");

    if (+price > +prize) {
      setErrorPrice("Не может быть больше начисления");
      return false;
    }

    if (nameObject.length < 1) {
      setErrorNameObj("Вы не ввели название");
      return false;
    }

    const data = {
      url,
      name: nameObject,
      typeObject,
      urlOnView,
      textOnView,
      price,
      prize,
      question,
      answer,
      amountAttempts,
      correctAnswer,
      wrongAnswer,
      color,
      hint,
      costPrompting
    };

    const objectCreated = await createEssence(data);

    navigate(`${ESSENCES_ROUTE}/${objectCreated.id}`);
  };

  const onEdit = async () => {
    setErrorPrice("");
    setErrorNameObj("");

    if (+price > +prize) {
      setErrorPrice("Не может быть больше начисления");
      return false;
    }

    if (nameObject.length < 1) {
      setErrorNameObj("Вы не ввели название");
      return false;
    }

    const data = {
      id: essenceCurrent.id,
      url,
      name: nameObject,
      typeObject,
      urlOnView,
      textOnView,
      price,
      prize,
      question,
      answer,
      amountAttempts,
      correctAnswer,
      wrongAnswer,
      color,
      hint,
      costPrompting
    };

    editEssences(data);
  };

  const onClear = () => {
    clearEssence(id);
  };

  return (
    <div className={classes["essences-sample"]}>
      <h2>{!essenceCurrent ? "Создать" : "Изменить"} объект</h2>
      {id ? (
        <Tags
          tags={tags}
          addTag={createTag}
          objectId={id}
          removeTag={removeTag}
          tagHintsAction={essenceTagHintsGet}
          tagHints={essenceTagHints}
        />
      ) : null}
      <div className={classes["essences-sample__wrap_inp"]}>
        <p>Тип объекта</p>
        <Select
          options={TYPE_OBJECTS}
          styles={customStyles}
          onChange={(e) => {
            setTypeObject(e.value);
          }}
          isDisabled={essenceCurrent && essenceCurrent.typeObject === "COUPON"}
          value={TYPE_OBJECTS.filter((option) => option.value === typeObject)}
        />
      </div>
      <div className={classes["essences-sample__wrap_inp"]}>
        <Input
          type={"text"}
          name={"name"}
          classNameInput={classes["essences-sample__wrap_inp--input"]}
          placeholder={"Введите название"}
          label={"Название"}
          value={nameObject}
          error={errorNameObj}
          onChange={(e) => setNameObject(e.target.value)}
        />
      </div>
      <div className={classes["essences-sample__wrap_inp"]}>
        <Input
          type={"text"}
          name={"url"}
          classNameInput={classes["essences-sample__wrap_inp--input"]}
          placeholder={"Введите ссылку"}
          label={"Ссылка на объект"}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      <div className={classes["essences-sample__wrap_inp"]}>
        <Input
          type={"text"}
          name={"urlOnView"}
          label={typeObject === "COUPON" ? "Ссылка на картинку при просмотре" : "Ссылка на превью"}
          classNameInput={classes["essences-sample__wrap_inp--input"]}
          placeholder={typeObject === "COUPON" ? "Введите ссылку картинки при просмотре" : "Введите ссылку на превью"}
          value={urlOnView}
          onChange={(e) => setUrlOnView(e.target.value)}
        />
      </div>
      {typeObject === "QUESTION" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <label htmlFor="quest" className={classes["essences-sample__textarea--label"]}>
            Вопрос
          </label>
          <textarea
            className={classes["essences-sample__textarea"]}
            name="quest"
            cols="30"
            rows="5"
            placeholder={"Введите вопрос"}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
      ) : null}
      {typeObject === "QUESTION" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <Input
            type={"text"}
            name={"answer"}
            label={"Ответ"}
            classNameInput={classes["essences-sample__wrap_inp--input"]}
            placeholder={"Введите ответ"}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>
      ) : null}
      {typeObject === "QUESTION" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <Input
            error={errorPrice}
            type={"number"}
            name={"price"}
            label={"Колличество попыток"}
            classNameInput={classes["essences-sample__wrap_inp--input"]}
            placeholder={"Введите колличество попыток"}
            value={amountAttempts}
            onChange={(e) => setAmountAttempts(e.target.value >= 0 ? e.target.value : 0)}
          />
        </div>
      ) : null}
      {typeObject === "POPUP" || typeObject === "COUPON" || typeObject === "PAID" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <label htmlFor="textOnView" className={classes["essences-sample__textarea--label"]}>
            Текст при просмотре
          </label>
          <textarea
            className={classes["essences-sample__textarea"]}
            name="textOnView"
            cols="30"
            rows="10"
            placeholder={"Введите текст при просмотре"}
            value={textOnView}
            onChange={(e) => setTextOnView(e.target.value)}
          />
        </div>
      ) : null}
      {typeObject === "PAID" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <Input
            error={errorPrice}
            type={"number"}
            name={"price"}
            label={"Стоимость в балах"}
            classNameInput={classes["essences-sample__wrap_inp--input"]}
            placeholder={"Введите стоимость"}
            value={price}
            onChange={(e) => setPrice(e.target.value >= 0 ? e.target.value : 0)}
          />
        </div>
      ) : null}
      {typeObject === "PAID" || typeObject === "QUESTION" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <Input
            type={"number"}
            name={"prize"}
            label={"Балы для начисления"}
            classNameInput={classes["essences-sample__wrap_inp--input"]}
            placeholder={"Введите балы для начисления"}
            value={prize}
            onChange={(e) => setPrize(e.target.value >= 0 ? e.target.value : 0)}
          />
        </div>
      ) : null}
      {typeObject === "QUESTION" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <label htmlFor="textOnView" className={classes["essences-sample__textarea--label"]}>
            Текст правильного ответа
          </label>
          <textarea
            className={classes["essences-sample__textarea"]}
            name="quest"
            cols="30"
            rows="5"
            placeholder={"Введите текст"}
            value={correctAnswer}
            onChange={(e) => setCorrectAnswer(e.target.value)}
          />
        </div>
      ) : null}
      {typeObject === "QUESTION" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <label htmlFor="textOnView" className={classes["essences-sample__textarea--label"]}>
            Текст неправильного ответа
          </label>
          <textarea
            className={classes["essences-sample__textarea"]}
            name="quest"
            cols="30"
            rows="5"
            placeholder={"Введите текст"}
            value={wrongAnswer}
            onChange={(e) => setWrongAnswer(e.target.value)}
          />
        </div>
      ) : null}
      {typeObject === "QUESTION" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <label htmlFor="hint" className={classes["essences-sample__textarea--label"]}>
            Текст подсказки
          </label>
          <textarea
            className={classes["essences-sample__textarea"]}
            name="hint"
            cols="30"
            rows="5"
            placeholder={"Введите текст"}
            value={hint}
            onChange={(e) => setHint(e.target.value)}
          />
        </div>
      ) : null}
      {typeObject === "QUESTION" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <Input
            error={errorPrice}
            type={"number"}
            name={"costPrompting"}
            label={"Стоимость подсказки"}
            classNameInput={classes["essences-sample__wrap_inp--input"]}
            placeholder={"Введите стоимость подсказки"}
            value={costPrompting}
            onChange={(e) => setCostPrompting(e.target.value >= 0 ? e.target.value : 0)}
          />
        </div>
      ) : null}
      {typeObject === "BEACON" ? (
        <div className={classes["essences-sample__wrap_inp"]}>
          <p>Цвет объекта</p>
          <ColorBox onHandleColor={setColor} color={color} />
        </div>
      ) : null}
      <div className={classes["essences-sample__buttons"]}>
        {!essenceCurrent ? (
          <Button className={classes["essences-sample__buttons--button"]} onClick={onCreate}>
            Сохранить
          </Button>
        ) : (
          <Button className={classes["essences-sample__buttons--button"]} onClick={onEdit}>
            Изменить
          </Button>
        )}
        <Button className={classes["essences-sample__buttons--button"]} disabled={!essenceCurrent} onClick={onClear}>
          Очистить
        </Button>
      </div>
    </div>
  );
};

export default EssencesSample;
