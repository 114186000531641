import React from "react";
import styles from "./UserForm.module.scss";
import { DOMAIN } from "../../../../utils/constants/domain.constans";
import Input from "../../../../components/form/input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/button/Button";
import Form from "../../../../components/form/Form";

const UserForm = (props) => {
  const {
    file,
    errorLogin,
    errorEmail,
    login,
    email,
    pass,
    phone,
    last_name,
    first_name,
    patronymic,
    setLogin,
    setEmail,
    setPass,
    setPhone,
    setLastName,
    setFirstName,
    setPatronymic,
    handleUpload,
    submit,
    cancel
  } = props;

  return (
    <Form className={styles["user-form"]}>
      <div className={styles["user-form--upload-box"]}>
        {file ? (
          <div className={styles["user-form__wrap-ava"]}>
            <img src={`${DOMAIN}/public/avatars/${file.name}`} alt={file.name} />
            <Input type={"file"} name={"upload"} onChange={(e) => handleUpload(e)} />
          </div>
        ) : (
          <div className={styles["user-form__wrap-ava"]}>
            <FontAwesomeIcon icon={faUser} color={"#a8b2b5"} size="8x" />
            <Input type={"file"} name={"upload"} onChange={(e) => handleUpload(e)} />
          </div>
        )}
        <p>Чтобы изменить аватарку нажмите на нее</p>
      </div>
      <div className={styles["user-form__fields"]}>
        <Input
          type={"text"}
          name={"login"}
          label={"Login"}
          value={login}
          placeholder={"Введите login"}
          error={errorLogin}
          onChange={(e) => setLogin(e.target.value)}
        />
        <Input
          type={"email"}
          name={"email"}
          label={"Email"}
          value={email}
          placeholder={"Введите email"}
          error={errorEmail}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type={"password"}
          name={"pass"}
          label={"Пароль"}
          value={pass}
          placeholder={"Введите новый пароль"}
          onChange={(e) => setPass(e.target.value)}
        />
        <Input
          type={"number"}
          name={"phone"}
          label={"Телефон"}
          value={phone}
          placeholder={"Введите телефон"}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Input
          type={"text"}
          name={"last_name"}
          label={"Фамилия"}
          value={last_name}
          placeholder={"Введите Фамилию"}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input
          type={"text"}
          name={"first_name"}
          label={"Имя"}
          value={first_name}
          placeholder={"Введите Имя"}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          type={"text"}
          name={"patronymic"}
          label={"Отчество"}
          value={patronymic}
          placeholder={"Введите Отчество"}
          onChange={(e) => setPatronymic(e.target.value)}
        />
      </div>
      <Button className={styles["user-form__button"]} onClick={submit}>
        Сохранить
      </Button>
      <Button className={styles["user-form__button--cancel"]} type="button" onClick={cancel}>
        Отменить
      </Button>
    </Form>
  );
};

export default UserForm;
