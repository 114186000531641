import { GET_BANNERS, EDIT_BANNERS, PAGINATION_PARAMS_BANNERS, TOTAL_PAGES_BANNERS, COUNT_BANNERS } from "./actionType";
import { Api } from "../../services/api";
import { Resource } from "../../services/ApiResources";
import { ERROR, SUCCESS } from "../status/actionType";

export const uploadBanner = (formData) => {
  return async (dispatch) => {
    try {
      const response = await Api.POST(Resource.BANNERS_UPLOAD, formData);
      dispatch({
        type: SUCCESS,
        payload: response.success
      });
      return response.fileName;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const changePaginationParamsBanners = (paginationParams) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_PARAMS_BANNERS,
      payload: paginationParams
    });
  };
};

export const getBanners = (paginationParams) => {
  return async (dispatch) => {
    try {
      const response = await Api.GET(
        {
          url: Resource.BANNERS
        },
        paginationParams
      );
      if (response) {
        dispatch({
          type: TOTAL_PAGES_BANNERS,
          payload: response.totalPages
        });
        dispatch({
          type: COUNT_BANNERS,
          payload: response.banners.count
        });
        dispatch({
          type: GET_BANNERS,
          payload: response.banners.rows
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getCurrentBanners = (id) => {
  return async () => {
    try {
      return await Api.GET({
        id,
        url: Resource.BANNERS
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createBanners = (data) => {
  return async (dispatch) => {
    try {
      const response = await Api.POST(Resource.BANNERS, data);
      dispatch({
        type: SUCCESS,
        payload: response.success
      });
      return response.banner;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const editBanners = (formData) => {
  return async (dispatch, getState) => {
    try {
      const { data } = getState().banners;
      const response = await Api.PUT(Resource.BANNERS, formData);
      if (response && (!data || !data.length)) {
        dispatch({
          type: EDIT_BANNERS,
          payload: [response.banner]
        });
        dispatch({
          type: SUCCESS,
          payload: response.success
        });
      }
      if (response && data && data.length) {
        const upload_data = data.map((el) => (el.id === response.banner.id ? response.banner : el));
        dispatch({
          type: EDIT_BANNERS,
          payload: upload_data
        });
        dispatch({
          type: SUCCESS,
          payload: response.success
        });
      }

      return response.banner;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const clearBanners = (id) => {
  return async (dispatch) => {
    try {
      const response = await Api.PUT(Resource.BANNERS_CLEAR, { id });
      dispatch({
        type: EDIT_BANNERS,
        payload: [response.banner]
      });
      dispatch({
        type: SUCCESS,
        payload: response.success
      });
      return response.banner;
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};

export const deleteBanners = (id) => {
  return async (dispatch) => {
    try {
      const response = await Api.DELETE(Resource.BANNERS, id);
      if (response) {
        dispatch({
          type: SUCCESS,
          payload: response.success
        });
      }
    } catch (e) {
      if (typeof e.response.data.message === "string")
        dispatch({
          type: ERROR,
          payload: e.response.data.message
        });
    }
  };
};
