export const Resource = {
  USERS: "/users",
  AUTH: "/users/auth",
  LOGIN: "/users/login",
  UPLOAD_AVATAR: "/users/avatars",
  USER_EDIT: "/users/edit",
  SCENES: "/scenes",
  SCENES_EDIT: "/scenes/edit",
  SCENES_CHANGE_TEAM_GAME: "/scenes/team-game",
  TAGS: "/tags",
  TAG_HINTS: "/tags/hints",
  VIRTUAL_OBJECT: "/virtual-object",
  VIRTUAL_OBJECT_TOGGLE_CONSISTENT: "/virtual-object/change-consistent",
  BEACON_CHILDREN: "/virtual-object/beacon-children",
  BEACON_CHILDREN_EDIT: "/virtual-object/edit/beacon-children",
  VIRTUAL_OBJECT_CLEAR: "/virtual-object/clear",
  VIRTUAL_OBJECT_EDIT: "/virtual-object/edit",
  STATISTIC_GAMES: "/statistic-games",
  STATISTIC_TEAMS: "/statistic-games/scene-teams",
  USER_GAME_STATISTIC: "/statistic-games/user",
  BANNERS_UPLOAD: "/banners/upload",
  BANNERS: "/banners",
  BANNERS_CLEAR: "/banners/clear",
  ESSENCES: "/essences",
  ESSENCES_CLEAR: "/essences/clear",
  POLYGON: "/polygons",
  POLYGON_COORDINATES: "/polygons/coordinates",
  TEAMS: "/teams"
};
