import React, { useEffect, useState } from "react";
import classes from "./UserEdit.module.scss";
import { connect } from "react-redux";
import { editUser, getUser, uploadAvatar } from "../../../store/users/action";
import { useNavigate, useParams } from "react-router-dom";
import { USERS_ROUTE } from "../../../utils/constants/routes.constants";
import UserForm from "../components/user-form/UserForm";
import UserStatisticList from "../components/user-statistic-list/UserStatisticList";
import Table from "../../../components/layout/table/Table";
import { getUserGame, resetUserGame } from "../../../store/statistic/user-game-statistic/action";

const UserEdit = (props) => {
  const { user_games, getUser, uploadAvatar, editUser, getUserGame, resetUserGame } = props;
  const params = useParams();
  const navigate = useNavigate();

  const [file, setFile] = useState("");
  const [idUser, setIdUser] = useState("");
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [phone, setPhone] = useState("");
  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  useEffect(() => {
    (async () => {
      getUserGame(params.id);
      const user = await getUser(params.id);
      if (user.avatar)
        setFile({
          name: user.avatar
        });
      setIdUser(user.id);
      setLogin(user.login);
      setEmail(user.email);
      setPhone(user.phone);
      setLastName(user.last_name);
      setFirstName(user.first_name);
      setPatronymic(user.patronymic);
    })();
  }, []);

  const handleUpload = async (event) => {
    const formData = new FormData();

    formData.append("img", event.target.files[0], event.target.files[0].name);

    formData.append("id", idUser);

    const res = await uploadAvatar(formData);
    if (res.avatar_name)
      setFile({
        name: res.avatar_name
      });
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
    );
  };

  const submit = async () => {
    setErrorLogin("");
    setErrorEmail("");
    if (!login.length) return setErrorLogin("Не может быть пустым");
    if (!email.length) return setErrorEmail("Не меньше 2х символов");

    if (!validateEmail(email)) return setErrorEmail("Не правильный формат email");

    const data = {
      id: params.id,
      login,
      email,
      pass,
      phone,
      last_name,
      first_name,
      patronymic
    };

    const res = await editUser(data);
    if (res) navigate(`${USERS_ROUTE}`);
  };

  const cancel = () => {
    navigate(`${USERS_ROUTE}`);
  };

  return (
    <div className={classes["user-edit"]}>
      <UserForm
        file={file}
        errorLogin={errorLogin}
        errorEmail={errorEmail}
        login={login}
        email={email}
        pass={pass}
        phone={phone}
        last_name={last_name}
        first_name={first_name}
        patronymic={patronymic}
        setLogin={setLogin}
        setEmail={setEmail}
        setPass={setPass}
        setPhone={setPhone}
        setLastName={setLastName}
        setFirstName={setFirstName}
        setPatronymic={setPatronymic}
        handleUpload={handleUpload}
        submit={submit}
        cancel={cancel}
      />
      <h2>Статистика игр</h2>
      <div className={classes["user-edit__table"]}>
        <Table>
          <thead>
          <tr>
            <td>Id игры</td>
            <td>Название игры</td>
            <td>Набранные очки</td>
            <td>Закончена</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {user_games
              ? user_games.map((item) => <UserStatisticList key={item.id} item={item} resetUserGame={resetUserGame} />)
              : null}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_games: state.statistic.user_game.user_games
});

const mapDispatchToProps = {
  getUser,
  uploadAvatar,
  editUser,
  getUserGame,
  resetUserGame
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
