import React from "react";

const Form = (props) => {
  const { children, className } = props;

  return (
    <form className={className} onSubmit={(e) => e.preventDefault()} noValidate={true}>
      {children}
    </form>
  );
};

export default Form;
