import React from "react";
import styles from "./UserStatisticList.module.scss";
import { formattedDate } from "../../../../helpers/dateHelper";

const UserStatisticList = (props) => {
  const { item, resetUserGame } = props;

  const resetStatistic = () => {
    resetUserGame(item.userId, item.sceneId);
  };

  return (
    <tr className={styles["user-statistic-list"]} style={{ background: item.end_time ? "#a81d1d4d" : "#8aad034d" }}>
      <td>{item.scene.id}</td>
      <td>{item.scene.name_game}</td>
      <td>{item.points}</td>
      <td>{formattedDate(item.end_time, true)}</td>
      <td onClick={resetStatistic} className={styles["user-statistic-list__reset"]}>
        сбросить
      </td>
    </tr>
  );
};

export default UserStatisticList;
