export const formattedDate = (date, withTime) => {
  if (date) {
    const newDate = new Date(date);
    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth() + 1;
    let dd = newDate.getDate();
    let hh = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (hh < 10) hh = "0" + hh;
    if (min < 10) min = "0" + min;
    if (sec < 10) sec = "0" + sec;

    if (withTime) return dd + "." + mm + "." + yyyy + " " + hh + ":" + min + ":" + sec;
    return dd + "." + mm + "." + yyyy;
  }
};
