import axios from "axios";

const $Host = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEVELOPMENT
      : process.env.REACT_APP_API_URL_PRODUCTION
});

const authInterceptor = (config) => {
  config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
};

$Host.interceptors.request.use(authInterceptor);

class RestApi {
  async GET(req, params) {
    const { id, url } = req;
    let param;
    if (params)
      param = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");

    let urlPath = url;
    if (id) urlPath = `${url}/${id}`;
    if (params) urlPath = `${url}/?${param}`;
    if (id && params) urlPath = `${url}/${id}/?${param}`;

    const { data } = await $Host.get(urlPath);

    return data;
  }

  async POST(url, req) {
    const { data } = await $Host.post(url, req);
    return data;
  }

  async PUT(url, req) {
    const { data } = await $Host.put(url, req);
    return data;
  }

  async DELETE(url, id, params) {
    let param;
    if (params)
      param = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");

    const { data } = await $Host.delete(params ? `${url}/${id}?${param}` : `${url}/${id}`);
    return data;
  }
}

const Api = new RestApi();

export { $Host, Api };
