import React from "react";
import styles from "./styles.module.scss";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/form/input/Input";
import Select from "react-select";
import { COLOR_MARKER_OBJECT } from "../../../../../utils/constants/objects.constants";
import Form from "../../../../../components/form/Form";

const customStyles = {
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    height: "14px",
    width: "14px",
    alignItems: "center"
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none"
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0
  }),
  control: (provided) => ({
    ...provided,
    "borderRadius": "0px",
    "border": 0,
    "borderBottom": "solid",
    "borderBottomWidth": "1px",
    "fontSize": "12px",
    "width": "100%",
    "minHeight": "auto",
    "boxShadow": "none",
    "&:hover": {
      borderColor: "none"
    }
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: "12px",
    maxHeight: "200px"
  }),
  option: (provided) => ({
    ...provided,
    padding: "5px"
  })
};

const BeaconChildrenForm = (props) => {
  const {
    beacon,
    essenceBeaconSelected,
    essenceOptions,
    errorEssenceBeacon,
    setBeacon,
    setEssenceBeaconSelected,
    onEditBeacon,
    removeBeacon
  } = props;

  return (
    <Form className={styles["beacon-form"]}>
      <h2>Редактирование маяка</h2>
      <div className={styles["beacon-form__buttons"]}>
        <Button className={styles["beacon-form__buttons--edit"]} onClick={onEditBeacon}>
          Изменить
        </Button>
        <Button className={styles["beacon-form__buttons--remove"]} onClick={removeBeacon}>
          Удалить
        </Button>
      </div>
      <div className={styles["beacon-form__fields"]}>
        <Input
          type={"number"}
          name={"longitude_beacon"}
          classNameLabel={styles["beacon-form__fields--label"]}
          classNameWrap={styles["beacon-form__fields--wrap"]}
          classNameInput={styles["beacon-form__fields--input"]}
          label={"Долгота"}
          value={beacon.longitude}
          onChange={(e) => setBeacon((data) => ({ ...data, longitude: e.target.value }))}
        />
        <Input
          type={"number"}
          name={"latitude_beacon"}
          classNameLabel={styles["beacon-form__fields--label"]}
          classNameWrap={styles["beacon-form__fields--wrap"]}
          classNameInput={styles["beacon-form__fields--input"]}
          label={"Широта"}
          value={beacon.latitude}
          onChange={(e) => setBeacon((data) => ({ ...data, latitude: e.target.value }))}
        />
        <Input
          type={"number"}
          name={"size_beacon"}
          classNameLabel={styles["beacon-form__fields--label"]}
          classNameWrap={styles["beacon-form__fields--wrap"]}
          classNameInput={styles["beacon-form__fields--input"]}
          label={"Размер (см)"}
          value={beacon.size}
          onChange={(e) =>
            setBeacon((data) => ({
              ...data,
              size: e.target.value.length <= 6 ? e.target.value : beacon.size
            }))
          }
        />
        <Input
          type={"number"}
          name={"height_beacon"}
          classNameLabel={styles["beacon-form__fields--label"]}
          classNameWrap={styles["beacon-form__fields--wrap"]}
          classNameInput={styles["beacon-form__fields--input"]}
          label={"Высота (см)"}
          value={beacon.height}
          onChange={(e) =>
            setBeacon((data) => ({
              ...data,
              height: e.target.value.length <= 6 ? e.target.value : beacon.height
            }))
          }
        />
        <div className={styles["beacon-form__select_wrap"]}>
          <p>Объект</p>
          <Select
            options={essenceOptions.filter((option) => option.type === "BEACON")}
            styles={customStyles}
            onChange={(e) => setEssenceBeaconSelected(e.value)}
            placeholder={"Выберите объект"}
            value={essenceOptions.filter((option) => option.value === essenceBeaconSelected)}
          />
          {errorEssenceBeacon && <p className={styles["beacon-form__select_wrap--error"]}>{errorEssenceBeacon}</p>}
        </div>
        <div className={styles["beacon-form__select_wrap"]}>
          <p>Цвет маркера</p>
          <Select
            options={COLOR_MARKER_OBJECT}
            styles={customStyles}
            onChange={(e) => setBeacon((data) => ({ ...data, mark_color: e.value }))}
            value={COLOR_MARKER_OBJECT.filter((option) => option.value === beacon.mark_color)}
          />
        </div>
      </div>
    </Form>
  );
};

export default BeaconChildrenForm;
