import { Api } from "../../../services/api";
import { Resource } from "../../../services/ApiResources";
import {
  GET_USER_GAME_STATISTIC,
  TOTAL_PAGES_USER_GAME_STATISTIC,
  PAGINATION_PARAMS_USER_GAME_STATISTIC,
  COUNT_USER_GAME_STATISTIC,
  GET_USER_GAMES,
  RESET_USER_GAMES
} from "./actionType";
import { confirmAlert } from "react-confirm-alert";

export const getUserGameStatistic = (paginationParams, id, sortConfig) => {
  return async (dispatch) => {
    let param = {
      ...paginationParams,
      id
    };

    if (sortConfig)
      param = {
        ...param,
        sortKey: sortConfig.key,
        direction: sortConfig.direction
      };

    try {
      const response = await Api.GET(
        {
          url: Resource.USER_GAME_STATISTIC
        },
        param
      );
      if (response) {
        dispatch({
          type: TOTAL_PAGES_USER_GAME_STATISTIC,
          payload: response.totalPages
        });
        dispatch({
          type: COUNT_USER_GAME_STATISTIC,
          payload: response.user_game_statistic.count
        });
        dispatch({
          type: GET_USER_GAME_STATISTIC,
          payload: response.user_game_statistic.rows
        });
      }
    } catch (e) {
      dispatch({
        type: TOTAL_PAGES_USER_GAME_STATISTIC,
        payload: null
      });
      dispatch({
        type: COUNT_USER_GAME_STATISTIC,
        payload: null
      });
      dispatch({
        type: GET_USER_GAME_STATISTIC,
        payload: null
      });
      console.log(e);
    }
  };
};

export const toggleShowStatisticGames = (id, data) => {
  return async (dispatch, getState) => {
    try {
      const { user_game_statistic } = getState().statistic.user_game;

      const response = await Api.PUT(`${Resource.STATISTIC_GAMES}/${id}`, data);

      if (response) {
        const newStatistic = user_game_statistic.map((el) => (el.id === response.id ? response : el));

        dispatch({
          type: GET_USER_GAME_STATISTIC,
          payload: newStatistic
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const changePaginationParamsUserGameStatistic = (paginationParams) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_PARAMS_USER_GAME_STATISTIC,
      payload: paginationParams
    });
  };
};

export const getUserGame = (userId) => {
  return async (dispatch) => {
    try {
      const response = await Api.GET({
        url: `${Resource.USER_GAME_STATISTIC}/${userId}`
      });
      if (response) {
        dispatch({
          type: GET_USER_GAMES,
          payload: response.user_games
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const resetUserGame = (userId, sceneId) => {
  return async (dispatch, getState) => {
    try {
      const { user_games } = getState().statistic.user_game;

      confirmAlert({
        title: "Подтверждение",
        message: "Сбросить игру?",
        buttons: [
          {
            label: "Да",
            onClick: async () => {
              const response = await Api.PUT(`${Resource.USER_GAME_STATISTIC}/${userId}/reset/${sceneId}`);
              if (response) {
                const indexUserGames = user_games.findIndex((item) => item.id === response.user_game.id);
                user_games[indexUserGames] = { ...response.user_game };

                dispatch({
                  type: RESET_USER_GAMES,
                  payload: user_games
                });
              }
            }
          },
          {
            label: "Нет"
          }
        ]
      });
    } catch (e) {
      console.log(e);
    }
  };
};
