import { faBarChart, faDiceD6, faUsers, faImage } from "@fortawesome/free-solid-svg-icons";
import { BANNERS_ROUTE, ESSENCES_ROUTE, SCENES_ROUTE, STATISTIC_ROUTE, USERS_ROUTE } from "./routes.constants";

export const DashboardMenu = [
  {
    id: 1,
    title: "Сцены",
    icon: faDiceD6,
    pathname: SCENES_ROUTE
  },
  {
    id: 2,
    title: "Объекты",
    icon: faImage,
    pathname: ESSENCES_ROUTE
  },
  {
    id: 3,
    title: "Игроки",
    icon: faUsers,
    pathname: USERS_ROUTE
  },
  {
    id: 4,
    title: "Статистика",
    icon: faBarChart,
    pathname: STATISTIC_ROUTE
  },
  {
    id: 5,
    title: "Баннер",
    icon: faImage,
    pathname: BANNERS_ROUTE
  }
];
