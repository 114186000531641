import React, { useEffect, useState } from "react";
import classes from "./Essences.module.scss";
import { connect } from "react-redux";
import Table from "../../components/layout/table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/layout/pagination/Pagination";
import CircleButton from "../../components/button/circle-button/CircleButton";
import { ESSENCES_CREATE_ROUTE, ESSENCES_ROUTE } from "../../utils/constants/routes.constants";
import {
  changePaginationParamsEssences, cloneEssence,
  deleteEssence,
  essenceTagHintsGet,
  getEssences
} from "../../store/essences/action";
import { useNavigate } from "react-router-dom";
import { showModal } from "../../store/modal/action";
import TagSort from "../../components/hashtags/TagSort";

const Essences = (props) => {
  const {
    essenceTagHints,
    essences,
    getEssences,
    deleteEssence,
    changePaginationParamsEssences,
    paginationParams,
    totalPages,
    count,
    showModal,
    essenceTagHintsGet,
    cloneEssence
  } = props;

  const [sortTag, setSortTag] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (sortTag) {
      changePaginationParamsEssences({
        pageSize: 999999,
        page: 0
      });
    }
    getEssences({ ...paginationParams, sortTag });
  }, [sortTag]);

  useEffect(() => {
    getEssences({ ...paginationParams, sortTag });
  }, [paginationParams]);

  if (!essences) {
    return (
      <div className={classes.empty}>
        Нет объектов
        <CircleButton path={ESSENCES_CREATE_ROUTE} className={classes["essences__circle-button"]}>
          <FontAwesomeIcon icon={faPlus} size="2x" />
        </CircleButton>
      </div>
    );
  }

  const onDelete = async (id) => {
    await deleteEssence({ ...paginationParams, sortTag, id });
  };

  const editObject = (id) => {
    navigate(`${ESSENCES_ROUTE}/${id}`);
  };

  const showImg = (e) => {
    showModal({
      title: "",
      children: `<img class=${classes.modal_img} src=${e.target.src} />`
    });
  };

  const onCloneEssence = async (el) => {
    cloneEssence(el);
  };

  return (
    <div className={classes.essences}>
      <div className={classes.essences__tagSort}>
        <TagSort tagHints={essenceTagHints} tagHintsAction={essenceTagHintsGet} setSortTag={setSortTag} />
      </div>
      <div className={classes.essences__table_wrap}>
        <Table>
          <thead>
            <tr>
              <td>Предпросмотр</td>
              <td>Название объекта</td>
              <td>Ссылка</td>
              <td>Тип</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {essences !== null
              ? essences.map((el) => (
                  <tr key={el.id}>
                    <td>
                      {el.typeObject === "BEACON" ? (
                        <div className={classes.essences__beacon_color} style={{ background: el.color }}></div>
                      ) : (
                        <img src={el.typeObject === "COUPON" ? el.url : el.urlOnView} onClick={(e) => showImg(e)} />
                      )}
                    </td>
                    <td>{el.name}</td>
                    <td>{el.url}</td>
                    <td>{el.typeObject}</td>
                    <td className={classes.essences__button_control}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        color={"#a8b2b5"}
                        onClick={() => editObject(el.id)}
                        className={`${classes["essences__control"]} ${classes["essences__control--edit"]}`}
                        size="1x"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        color={"#a8b2b5"}
                        onClick={() => onDelete(el.id)}
                        className={`${classes["essences__control"]} ${classes["essences__control--remove"]}`}
                        size="1x"
                      />
                      <FontAwesomeIcon
                        icon={faClone}
                        color={"#a8b2b5"}
                        onClick={() => onCloneEssence(el)}
                        className={`${classes["essences__control"]} ${classes["essences__control--clone"]}`}
                        size="1x"
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
      {essences !== null ? (
        <Pagination
          countPage={totalPages}
          countElements={count}
          action={changePaginationParamsEssences}
          defaultParams={paginationParams}
        />
      ) : null}
      <CircleButton path={ESSENCES_CREATE_ROUTE} className={classes["essences__circle-button"]}>
        <FontAwesomeIcon icon={faPlus} size="2x" />
      </CircleButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  essences: state.essences.data,
  totalPages: state.essences.totalPages,
  paginationParams: state.essences.paginationParams,
  count: state.essences.count,
  essenceTagHints: state.essences.essenceTagHints
});

const mapDispatchToProps = {
  getEssences,
  deleteEssence,
  changePaginationParamsEssences,
  showModal,
  essenceTagHintsGet,
  cloneEssence
};

export default connect(mapStateToProps, mapDispatchToProps)(Essences);
